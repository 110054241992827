import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const LeftSidebar = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className={`left-sidebar ${isOpen ? 'open' : 'closed'}`}>
                <button onClick={toggleSidebar} className="sidebar-toggle-left">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {isOpen && (
                    <div className="sidebar-content-left">
                        <div className="sidebar-section">
                            <h4>Section 1</h4>
                            <p>Details for section 1</p>
                        </div>
                        <hr className="divider" />
                        <div className="sidebar-section">
                            <h4>Section 2</h4>
                            <p>Details for section 2</p>
                        </div>
                    </div>
                )}
            </div>
            {!isOpen && (
                <button onClick={toggleSidebar} className="sidebar-toggle-left-open">
                    <FontAwesomeIcon icon={faBars} />
                </button>
            )}
        </>
    );
};

export default LeftSidebar;
