import React, { useState, useEffect } from 'react';

function Sidebar({ isOpen, onClose }) {
    const [stockId, setStockId] = useState('');
    const [price, setPrice] = useState('');
    const [result, setResult] = useState(null);
    const [isCalculating, setIsCalculating] = useState(false);
    const [error, setError] = useState('');

    const handleCalculation = async () => {
        setIsCalculating(true);
        setError('');
        setResult(null);
        try {
            const response = await fetch('http://localhost:5000/calculate', {
                method: 'POST',
                headers: {
                    
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify({ stockId, price })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();
            setResult(data);
        } catch (error) {
            console.error('Failed to fetch:', error);
            setError(`Error: ${error.message}`);
        }

        setIsCalculating(false);
    };
  const sidebarStyle = {
    position: 'fixed',
    overflow: 'auto' ,
    top: 0,
    right: isOpen ? 0 : '-750px', // Hide sidebar by moving it off the screen
    width: '750px',
    height: '100%',
    backgroundColor: '#121212',
    transition: 'right 0.3s ease-in-out',
    padding: '10px',
    boxSizing: 'border-box',
    zIndex: 1000 // Ensure sidebar is on top of other components
  };

  return (
    <div style={sidebarStyle}>
      <button onClick={onClose} style={{ marginBottom: '10px' }}>關閉</button>
      <h1>競拍預測模組</h1>
      <div>
            <label>
                股票 ID:
                <input
                    type="text"
                    value={stockId}
                    onChange={e => setStockId(e.target.value)}
                    placeholder="輸入股票代號(4碼)"
                />
            </label>
            <label>
                轉換價格:
                <input
                    type="text"
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                    placeholder="輸入轉換價格"
                />
            </label>
            <button onClick={handleCalculation} disabled={isCalculating}>
                {isCalculating ? 'Calculate' : 'Calculate'}
            </button>
            <div className="result">
                {isCalculating ? '' :
                 error ? `Error: ${error}` :
                 result ? `目標股價: ${result.stockPrice}` : null}
            </div>
            <div className="result">
                {isCalculating ? '' :
                 error ? `Error: ${error}` :
                 result ? `目標Parity: ${parseFloat(result.parity).toFixed(2)} ` : null}
            </div>
            <div className="result">
                {isCalculating ? '計算中...' :
                 error ? `Error: ${error}` :
                 result ? `預測結果: ${parseFloat(result.price).toFixed(2)}` : null}
            </div>
 </div>
                
      <ul>
     
        </ul>
      <h3>額外資訊</h3>
      <ul>
        <li><a href="https://mops.twse.com.tw/mops/web/index" target="_blank" rel="noopener noreferrer">公開資訊觀測站</a></li>
        <li><a href=" https://web.twsa.org.tw/edoc2/default.aspx" target="_blank" rel="noopener noreferrer">競拍公告</a></li>
        <li><a href="https://www.twse.com.tw/pcversion/zh/page/announcement/auction.html" target="_blank" rel="noopener noreferrer">競價拍賣公告-投標日程表</a></li>
        <li><a href="https://www.tpex.org.tw/web/index.php?l=zh-tw" target="_blank" rel="noopener noreferrer">證卷櫃買中心</a></li>
         </ul>
            
    </div>
  );
}

export default Sidebar;
