import React, { createContext, useContext, useState } from 'react';

const CrosshairSyncContext = createContext({
  syncCrosshair: (time, series) => {},
  syncRange: (range) => {},
});

export const CrosshairSyncProvider = ({ children }) => {
  const [crosshairPosition, setCrosshairPosition] = useState();
  const [visibleRange, setVisibleRange] = useState();

  const syncCrosshair = (time, series) => {
    setCrosshairPosition({ time, series });
  };

  const syncRange = (range) => {
    setVisibleRange(range);
  };

  return (
    <CrosshairSyncContext.Provider value={{ syncCrosshair, syncRange }}>
      {children}
    </CrosshairSyncContext.Provider>
  );
};

export const useCrosshairSync = () => useContext(CrosshairSyncContext);
