import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const RightSidebar = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
<>
    <div className={`right-sidebar ${isOpen ? 'open' : 'closed'}`}>
        <button onClick={toggleSidebar} className="sidebar-toggle">
            <FontAwesomeIcon icon={faTimes} />
        </button>
        {isOpen && (
            <div className="sidebar-content">
                <div className="sidebar-section">
                    <h4>觀察清單</h4>
                    <p>商品代碼 最新價 漲跌</p>
                </div>
                <hr className="divider" />
                <div className="sidebar-section">
                    <h4>Additional Information</h4>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                    <p>Here you can add details.</p>
                </div>
            </div>
        )}
    </div>
    {!isOpen && (
        <button onClick={toggleSidebar} className="sidebar-toggle-open">
            <FontAwesomeIcon icon={faBars} />
        </button>
    )}
</>
    );
};

export default RightSidebar;
