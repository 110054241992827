import React, { useEffect, useState, useCallback, useRef } from 'react';
import Papa from 'papaparse';
import './App.css'; 
import useResponsiveFontSize from './hooks/useResponsiveFontSize'; // Ensure the path is correct

const InfoPanel = ({ symbol }) => {
    const [bondData, setBondData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const infoPanelRef = useRef(null);
    const textContent = bondData ? `債券簡稱： ${bondData.bond_id} ${bondData.bond_name || "N/A"}  競拍/詢圈: ${bondData.auction_private || "N/A"}  TCRI: ${bondData.TCRI || "N/A"}  發行年期： ${bondData.time_horizon || "N/A"}  發行總額： ${bondData.issuance_val_str || "N/A"}  發行張數： ${bondData.num_issued || "N/A"}張 - 發行日期： ${bondData.issue_date || "N/A"} - 擔保情形 ${bondData.is_collateralized === '0.0' ? '無' : '有'} 轉換起日： ${bondData.convert_start_date || "N/A"} 轉換迄日：${bondData.convert_end_date || "N/A"}  CBAS: ${bondData.cbas === "False" ? '不可拆解' : '可拆解'}` : '';
    
    const { fontSize, textMeasureRef } = useResponsiveFontSize(infoPanelRef, textContent);

    const fetchBondData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://cb.gutalk.ai/complete_cb_master_data1.csv');
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const csvData = await response.text();
            Papa.parse(csvData, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                   
                    const filteredData = results.data.find(item => item.bond_id === symbol.trim());
                    console.log('filtered_data', filteredData)
                    setBondData(filteredData || null);
                },
                error: (error) => {
                    console.error('Parsing error:', error);
                    setError('Failed to parse bond data');
                }
            });
        } catch (error) {
            console.error('Error fetching or parsing bond data:', error);
            setError('Failed to fetch bond data');
        } finally {
            setLoading(false);
        }
    }, [symbol]);

    useEffect(() => {
        if (symbol) {
            fetchBondData();
        }
    }, [symbol, fetchBondData]);

    if (loading) {
        return <div>Loading bond data...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!bondData) {
        return <div>No bond data available for the symbol: {symbol}.</div>;
    }


    const bondDetails = `債券簡稱： ${bondData.bond_id} ${bondData.bond_name || "N/A"}  競拍/詢圈: ${bondData.auction_private || "N/A"}  TCRI: ${bondData.TCRI || "N/A"}  發行年期： ${bondData.time_horizon || "N/A"}  發行總額： ${bondData.issuance_val_str || "N/A"}  發行張數： ${bondData.num_issued || "N/A"}張 - 發行日期： ${bondData.issue_date || "N/A"} - 擔保情形 ${bondData.is_collateralized === '0.0' ? '無' : '有'} 轉換起日： ${bondData.convert_start_date 
        || "N/A"} 轉換迄日：${bondData.convert_end_date || "N/A"}  CBAS: ${bondData.cbas === "False" ? '不可拆解' : '可拆解'}`;

    return (
        <div ref={infoPanelRef} className="info-panel" style={{ fontSize }}>
            <span ref={textMeasureRef} className="hidden-text">{textContent}</span>
            <div>
    <span style={{ color: 'rgb(0, 140, 255)' }}>債券簡稱： </span> {bondData?.bond_id} {bondData?.bond_name || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> 競拍/詢圈: </span> {bondData?.auction_private || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> TCRI: </span> {bondData?.TCRI || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> 發行年期： </span> {bondData?.time_horizon || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> 發行總額： </span> {bondData?.issuance_val_str || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> 發行張數： </span> {bondData?.num_issued || "N/A"} 張   
    <span style={{ color: 'rgb(0, 140, 255)' }}> 發行日期： </span> {bondData?.issue_date || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> 擔保情形: </span> {bondData?.is_collateralized === '0.0' ? '無' : '有'}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> 轉換起日：</span> {bondData?.convert_start_date || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> 轉換迄日：</span> {bondData?.convert_end_date || "N/A"}  
    <span style={{ color: 'rgb(0, 140, 255)' }}> CBAS: </span> {bondData?.cbas === "False" ? '不可拆解' : '可拆解'}
</div>
        </div>
    );
};

export default InfoPanel;