// SearchBar.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCheckSquare, faIndustry, faNewspaper, faChartLine, faStar } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ onSearch, onToggleSidebar, openLoginModal, isLoggedIn , username, handleLogout  }) => {
    const [input, setInput] = useState('');
    const [activeIcon, setActiveIcon] = useState('選股');
    const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
    useEffect(() => {
        console.log("SearchBar Mounted");
        return () => console.log("SearchBar Unmounted");
    }, []);
    const handleIconClick = (label) => {
        setActiveIcon(label);
    };
    const handleSearch = e => {
        e.preventDefault();
        onSearch(input);
    };
    const SidebarIcon = ({ label, icon, isActive, onClick }) => (
        <div className={`sidebar-icon ${isActive ? 'active' : ''}`} onClick={onClick}>
            <FontAwesomeIcon icon={icon} />
            <span>{label}</span>
        </div>
    );
    const toggleLeftSidebar = () => {
        setIsLeftSidebarOpen(!isLeftSidebarOpen);
    };
    return (
        <div className={`search-bar-wrapper  ${isLeftSidebarOpen ? 'left-sidebar-open' : ''}`}>
            <div className={`sidebar-left ${isLeftSidebarOpen ? 'open' : ''}`}>
                <button className="close-button" onClick={toggleLeftSidebar}>Close</button>
                <p>Left Sidebar Content Here</p>
                <p>Additional Links or Info</p>
            </div>
            <div className="search-bar-container" >
                <div className="logo-container"  onClick={toggleLeftSidebar}>
                    <img src="/cb_logo1.png" alt="Logo" className="logo" />
                </div>
                <form onSubmit={handleSearch} className="search-form">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="請輸入股市代號(CB 5位數)"
                        className="search-input"
                    />
                    <button type="submit" className="search-button">搜尋</button>
                    <button type="button" onClick={onToggleSidebar} className="info-button">額外資訊</button>
                </form>
                {isLoggedIn ? (
                    <> 
                <Link to="/stock">
                <SidebarIcon label="選股" icon={faChartLine} isActive={activeIcon === '選股'} onClick={() => handleIconClick('選股')} />
            </Link>
                <Link to="/finance">
                <SidebarIcon label="財務" icon={faCheckSquare} isActive={activeIcon === '財務'} onClick={() => handleIconClick('財務')} />
            </Link>
            <Link to="/branch">
                <SidebarIcon label="分點" icon={faChartLine} isActive={activeIcon === '分點'} onClick={() => handleIconClick('分點')} />
            </Link>
            <Link to="/industry">
                <SidebarIcon label="產業" icon={faIndustry} isActive={activeIcon === '產業'} onClick={() => handleIconClick('產業')} />
            </Link>
            <Link to="/news">
                <SidebarIcon label="新聞" icon={faNewspaper} isActive={activeIcon === '新聞'} onClick={() => handleIconClick('新聞')} />
            </Link>
            <Link to="/custom-stock">
                <SidebarIcon label="自選股" icon={faStar} isActive={activeIcon === '自選股'} onClick={() => handleIconClick('自選股')} />
            </Link>
            </>
                ) : (
                    <> 
                    <Link to="/stock">
                    <SidebarIcon label="選股" icon={faChartLine} isActive={activeIcon === '選股'} onClick={() => handleIconClick('選股')} />
                </Link>
                  
                </>
                )}
            {isLoggedIn ? (
                    <div className="login-button" onClick={handleLogout}>{username}</div>
                ) : (
                    <button className="login-button" onClick={openLoginModal}>登入</button>
                )}
            </div>
        </div>
    );
};

export default SearchBar;
