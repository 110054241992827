import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import Papa from 'papaparse';
import InfoPanel from './InfoPanel'; // Import the InfoPanel component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './App.css';
const ChartComponent = ({ symbol }) => {

   const [isOpen, setIsOpen] = useState(false);
    
        const togglePanel = () => {
            setIsOpen(!isOpen);
        };
        function toggleSidebar() {
            const sidebar = document.querySelector('.right-sidebar');
            const chartContainer = document.querySelector('.chart-container');
            
            if (sidebar.style.display === 'none') {
                sidebar.style.display = 'block';
                chartContainer.style.width = 'calc(100% - 250px)'; // Adjust the width when the sidebar is visible
            } else {
                sidebar.style.display = 'none';
                chartContainer.style.width = '100%'; // Full width when the sidebar is hidden
            }
        }
   // const [chartData2, setChartData2] = useState([]);
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRef = useRef(null); // Reference for candlestick series
    const volumeSeriesRef = useRef(null);
    const [seriesData, setSeriesData] = useState([]);
    // SMA
    const ma5SeriesRef = useRef(null);
    const ma10SeriesRef = useRef(null);
    const ma20SeriesRef = useRef(null);
    const ma60SeriesRef = useRef(null);

    const ma5SeriesRef2 = useRef(null);
    const ma10SeriesRef2 = useRef(null);
    const ma20SeriesRef2 = useRef(null);
    const ma60SeriesRef2 = useRef(null);

    const baselineSeriesRef = useRef(null); // Reference for baseline series
    const lineSeriesRef = useRef(null); // Reference for line series
    const lineSeriesRef1 = useRef(null); // Reference for line series
    const lineSeriesRef2 = useRef(null);
    const chartContainerRef2 = useRef(null);
    const chartRef2 = useRef(null);
    const seriesRef2 = useRef(null);
    const volumeSeriesRef2 = useRef(null);

    const chartContainerRef3 = useRef(null);
    const chartRef3 = useRef(null);
    const seriesRef3 = useRef(null);

    const chartContainerRef4 = useRef(null);
    const chartRef4 = useRef(null);
    const seriesRef4 = useRef(null);
    const volumeSeriesRef4 = useRef(null);

    const chartContainerRef5 = useRef(null);
    const chartRef5 = useRef(null);
    const seriesRef5 = useRef(null);
    const volumeSeriesRef5 = useRef(null);

    const chartContainerRef6 = useRef(null);
    const chartRef6 = useRef(null);
    const seriesRef6 = useRef(null);
    const volumeSeriesRef6 = useRef(null);

    const chartContainerRef7 = useRef(null);
    const chartRef7 = useRef(null);
    const seriesRef7 = useRef(null);

    const chartContainerRef8 = useRef(null);
    const chartRef8 = useRef(null);
    const seriesRef8 = useRef(null);
    const [showAdditionalCharts, setShowAdditionalCharts] = useState(false);
    const [additionalChartsCreated, setAdditionalChartsCreated] = useState(false);
    const [timeFrame, setTimeFrame] = useState('daily');
    const [legend, setLegend] = useState('');
    const [legend1, setLegend1] = useState('');
    const [legend3, setLegend3] = useState('');
    const [legend4, setLegend4] = useState('');
    const [legend5, setLegend5] = useState('');
    const [legend6, setLegend6] = useState('');
    const [legend7, setLegend7] = useState('');
    const [legend8, setLegend8] = useState('');
    const [legend_parity, setLegend_parity] = useState('');
    const [legend_premium, setLegend_premium] = useState('');
    const [legend_theoretical_price, setLegend_theoretical_price] = useState('');
    const [legend_theoretical_price1, setLegend_theoretical_price1] = useState('');
    const [legend_volume, setLegend_volume] = useState('');
    const [legend_margin_ratio, setLegend_margin_ratio] = useState('');
    const [legend_margin_lending, setLegend_margin_lending] = useState('');
    const [legend_margin_borrowing, setLegend_margin_borrowing] = useState('');
    const [legend_intraday_trade, setLegend_intraday_trade] = useState('');
    const [error, setError] = useState(''); // State to store errors
    const [selectedData, setSelectedData] = useState(null);
    const [lineVisible_premium, setLineVisible_premium] = useState(true);
    const [lineVisible_parity, setLineVisible_parity] = useState(true);
    const [lineVisible_theoretical_price, setLineVisible_theoretical_price] = useState(true);
    const [lineVisible_theoretical_price1, setLineVisible_theoretical_price1] = useState(true);
    const [lineVisible_volume, setLineVisible_volume] = useState(true);
    const [loading, setLoading] = useState(true);

    const [visibleChartGroup, setVisibleChartGroup] = useState('現股');
    const [visibleCharts, setVisibleCharts] = useState({
        '現股': true,
        'CB指標': true,
        '轉換情形': true,
        '資券': true,
    });

    const toggleChartVisibility = (chartKey) => {
       // console.log('setVisibleCharts')
     //   console.log(setVisibleCharts)
     //   console.log('chartKey')
     //   console.log(chartKey)
        setVisibleCharts((current) => {
          //  console.log('current')
         //   console.log(current)
            const newState = {
                '現股': false,
                'CB指標': false,
                '轉換情形': false,
                '資券': false,
            };
         //   console.log('newState')
         //   console.log(newState)
            newState[chartKey] = true;
        //    console.log('newState2')
        //    console.log(newState)
            return newState;
        });
       // console.log('setVisibleChartGroup11111')
      //  console.log(setVisibleChartGroup)
        setVisibleChartGroup(chartKey);
      //  console.log('setVisibleChartGroup')
      //  console.log(setVisibleChartGroup)
     //   console.log('visibleCharts')
      //  console.log(visibleCharts)
       
    };


    const [subCharts, setSubCharts] = useState({
        marginRatio: false,
        marginLending: false,
        marginBorrow: false,
        intradayTrade: false,
    });
    const getPreviousClose1 = (currentIndex) => {
        if (currentIndex > 0 && seriesData[currentIndex - 1]) {
            return seriesData[currentIndex - 1].close;
        }
        return undefined;
    };
    function getPreviousClose(series, paramTime) {
        const seriesData = series.data();
        if (!seriesData) {
            return null;
        }
    
        // Convert param.time to a comparable format
        //const paramTimeStr = new Date(paramTime * 1000).toISOString().split('T')[0];
    
        // Find the current index
        const currentIndex = seriesData.findIndex(d => {
            //const originalTime = new Date(d.time * 1000).toISOString().split('T')[0];
            return d.time === paramTime;
        });
    
        if (currentIndex > 0 ) {
           // console.log('seriesData[currentIndex - 1]')
           // console.log(seriesData[currentIndex - 1])
            return seriesData[currentIndex - 1].close; // Previous close price
        }
        return null;
    }
    const calculateAndFormatChange = (current, previous) => {
    if (current === undefined || previous === undefined) {
        return { absoluteChange: '0', percentageChange: '0%' };
    }
    const absoluteChange = (current >= previous ? '▲' : '▼') +(current - previous).toFixed(2);
    const percentageChange = (((current - previous) / previous) * 100).toFixed(2);
    const percentageChangeFormatted =  `${Math.abs(percentageChange)}%`;
    return {
        absoluteChange,
        percentageChange: percentageChangeFormatted,
    };
};
    const handleChartVisibility = (chartGroup) => {
        setVisibleChartGroup(currentGroup => {
            const newGroup = currentGroup === chartGroup ? '' : chartGroup;
            console.log("Updating visible chart group from", currentGroup, "to", newGroup);
            return newGroup;
        });
    };   

    function calculateMovingAverage(data, period) {
        const averages = [];

        for (let i = 0; i < data.length; i++) {
            if (i < period - 1) {
                averages.push({ time: data[i].time, value: undefined });
                continue;
            }
            let sum = 0;
            for (let j = i - period + 1; j <= i; j++) {
                sum += data[j].close;
            }
            averages.push({
                time: data[i].time,
                value: sum / period,
            });
        }

        return averages;
    }

    function updateSMALines(data, period, smaSeriesRef) {
        const averages = calculateMovingAverage(data, period);

        let color;
        switch (period) {
            case 5:
                color = 'blue'; // Color for MA5
                break;
            case 10:
                color = 'green'; // Color for MA10
                break;
            case 20:
                color = 'red'; // Color for MA20
                break;
            case 60:
                color = 'purple'; // Color for MA60
                break;
            default:
                color = 'black'; // Default color
                break;
        }

        if (smaSeriesRef.current) {
            smaSeriesRef.current.setData(averages);
        } else {
            const newSMASeries = chartRef.current.addLineSeries({
                color: color,
                lineWidth: 1,
            });
            newSMASeries.setData(averages);
            smaSeriesRef.current = newSMASeries;
        }
    }

    function updateSMALines2(data, period, smaSeriesRef) {
        const averages = calculateMovingAverage(data, period);

        let color;
        switch (period) {
            case 5:
                color = 'blue'; // Color for MA5
                break;
            case 10:
                color = 'green'; // Color for MA10
                break;
            case 20:
                color = 'red'; // Color for MA20
                break;
            case 60:
                color = 'purple'; // Color for MA60
                break;
            default:
                color = 'black'; // Default color
                break;
        }

        if (smaSeriesRef.current) {
            smaSeriesRef.current.setData(averages);
        } else {
            const newSMASeries = chartRef2.current.addLineSeries({
                color: color,
                lineWidth: 1,
            });
            newSMASeries.setData(averages);
            smaSeriesRef.current = newSMASeries;
        }
    }
    const syncTimeScale1 = (sourceChart, targetChart,targetChart1) => {
        sourceChart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            if (timeRange) {
                targetChart.timeScale().setVisibleLogicalRange(timeRange);
                targetChart1.timeScale().setVisibleLogicalRange(timeRange);
            }
        });
    };
    const syncTimeScale1111= (sourceChart, targetCharts) => {
        let timeRange;

        sourceChart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            timeRange = {
                from: Math.floor((Date.now() - 14 * 24 * 60 * 60 * 1000) / 1000), // 7 days ago
                to: Math.floor(Date.now() / 1000), // now
            };
            if (timeRange) {
                targetCharts.forEach((targetChart) => {
                    timeRange = {
                        from: Math.floor((Date.now() - 14 * 24 * 60 * 60 * 1000) / 1000), // 7 days ago
                        to: Math.floor(Date.now() / 1000), // now
                    };
                    targetChart.timeScale().setVisibleLogicalRange(timeRange);
                });
            }
        });
    
        // Initial synchronization
        const initialTimeRange = sourceChart.timeScale().getVisibleLogicalRange();
        if (initialTimeRange) {
            targetCharts.forEach((targetChart) => {
                timeRange = {
                    from: Math.floor((Date.now() - 14 * 24 * 60 * 60 * 1000) / 1000), // 7 days ago
                    to: Math.floor(Date.now() / 1000), // now
                };
                targetChart.timeScale().setVisibleLogicalRange(initialTimeRange);
            });
        }
    };
    
    // Usage example
   // syncTimeScale1(chart1, [chart2, chart3, chart4]);
    
    const syncTimeScale2 = (sourceChart, targetCharts) => {
        let isApplyingRange = false;
    
        sourceChart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
       
            console.log('timeRange')
            console.log(timeRange)
            if (!timeRange || isApplyingRange) {
                return;
            }
    
            isApplyingRange = true;
            console.log('targetCharts')
            console.log(targetCharts)
            targetCharts.forEach((targetChart) => {
                console.log('timeRange1')
                console.log(timeRange)
                targetChart.timeScale().setVisibleLogicalRange(timeRange);
            });
            isApplyingRange = false;
        });
    };
    const syncTimeScale = (sourceChart, targetCharts) => {
      //  const timeRange = sourceChart.timeScale().getVisibleRange();
        sourceChart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
        if (timeRange) {
            targetCharts.forEach(targetChart => {
             //   targetChart.timeScale().setVisibleRange(timeRange);
                targetChart.timeScale().setVisibleLogicalRange(timeRange);
            });
        }
    });
};
const syncTimeScale12 = (sourceChart, targetCharts) => {
    //synchronize();
    const timeRange = sourceChart.timeScale().getVisibleRange();
   // const synchronize = (timeRange) => {
        if (timeRange) {
            targetCharts.forEach((targetChart) => {
                
                targetChart.timeScale().setVisibleLogicalRange(timeRange);
             //   targetChart.timeScale().setVisibleRange(timeRange);
            });
        }
   // };
/*
    sourceChart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
      //  timeRange.from = 9814
     //   timeRange.to = 9999
        synchronize(timeRange);
        console.log('timeRange');
        console.log(timeRange);
    });
*/
    // Initial synchronization
    //const initialTimeRange = sourceChart.timeScale().getVisibleLogicalRange();
   // synchronize(initialTimeRange);
};

    
    // Usage example:
    //synchronizeCharts(chart1, [chart2, chart3, chart4]);
    // Usage example:
    //syncTimeScale(chart1, [chart2, chart3, chart4]);
    // Usage example:
    //syncTimeScale(chart1, [chart2, chart3, chart4]);
    
    function getCrosshairDataPoint(series, param) {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    }
    
    function syncCrosshair(chart, series, dataPoint) {
        // Log the dataPoint to see what is being received
        console.log("Received dataPoint:", dataPoint);
    
        if (dataPoint && dataPoint.value !== null && dataPoint.time !== null) {
           // console.log("Setting crosshair position to:", dataPoint.value, dataPoint.time, series);
            try {
                chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
                
            } catch (error) {
                console.error("Failed to set crosshair position:", error);
            }
        } else {
            console.log("Clearing crosshair position due to invalid dataPoint");
            chart.clearCrosshairPosition();
        }
    }

    const updateLegendPositions = () => {
        // Update the position of the legend elements based on the chart container size
        const chartContainer = chartContainerRef.current;
        const legendElements = document.querySelectorAll('.chart-legend');
        legendElements.forEach((element, index) => {
            element.style.left = `${chartContainer.clientWidth - 200}px`;
            element.style.top = `${index * 30}px`;
        });
    };

    const createChartWithConfig = (containerRef, chartRef, seriesRef, mainChartRef1, mainChartRef2) => {
        if (!containerRef.current) return null;
     //   console.log('Creating chart for:', containerRef.current); // Debug log
        const chart = createChart(containerRef.current, {
            width: containerRef.current.clientWidth,
            height: containerRef.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85,
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
        });
    
        chartRef.current = chart;
        seriesRef.current = chart.addLineSeries({
            color: '#ff8000',
            lineWidth: 1,
        });
    
        // Synchronize with main charts
        if (mainChartRef1 && mainChartRef2 && mainChartRef1.current && mainChartRef2.current) {
            syncTimeScale(mainChartRef1.current, chartRef.current);
            syncTimeScale(chartRef.current, mainChartRef1.current);
            syncTimeScale(mainChartRef2.current, chartRef.current);
            syncTimeScale(chartRef.current, mainChartRef2.current);
    
            mainChartRef1.current.subscribeCrosshairMove((param) => {
                if (mainChartRef1.current.seriesRef && mainChartRef1.current.seriesRef.current) {
                    const dataPoint = getCrosshairDataPoint(seriesRef.current, param);
                    syncCrosshair(chartRef.current, seriesRef.current, dataPoint);
                }
            });
            if (!chartRef.current || !mainChartRef1.current || !mainChartRef1.current.seriesRef || !mainChartRef1.current.seriesRef.current) {
                chartRef.current.subscribeCrosshairMove((param) => {
                if (mainChartRef1.current.seriesRef && mainChartRef1.current.seriesRef.current) {
                    const dataPoint = getCrosshairDataPoint(seriesRef.current, param);
                    syncCrosshair(mainChartRef1.current, mainChartRef1.current.seriesRef.current, dataPoint);
                }
            });
        }
            mainChartRef2.current.subscribeCrosshairMove((param) => {
                if (mainChartRef2.current.seriesRef && mainChartRef2.current.seriesRef.current) {
                    const dataPoint = getCrosshairDataPoint(seriesRef2.current, param);
                    syncCrosshair(chartRef.current, seriesRef.current, dataPoint);
                }
            });
    
            chartRef.current.subscribeCrosshairMove((param) => {
                if (mainChartRef2.current.seriesRef && mainChartRef2.current.seriesRef.current) {
                    const dataPoint = getCrosshairDataPoint(seriesRef.current, param);
                    syncCrosshair(mainChartRef2.current, mainChartRef2.current.seriesRef2.current, dataPoint);
                }
            });
        }
    
        return chart;
    };
    
    
    
    const removeChart = (chartRef) => {
        if (chartRef.current) {
            chartRef.current.remove();
            chartRef.current = null; // Reset the reference
        }
    };
    // Initialize main charts
    useEffect(() => {
        
        if (!chartContainerRef.current || !chartContainerRef2.current||!chartContainerRef3.current||!chartContainerRef4.current||!chartContainerRef5.current||!chartContainerRef6.current||!chartContainerRef7.current||!chartContainerRef8.current) {
            return;
        }
        // create cb chart
         
        const chart1 = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85, // These should match the needs of the associated series
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                // mode: 0,
            },
        });

        chartRef.current = chart1;
        // add candlestick
        seriesRef.current = chart1.addCandlestickSeries({
            priceScaleId: 'right',
            upColor: '#ef5350',
            downColor: '#26a69a',
            borderVisible: false,
            wickDownColor: '#26a69a',
            wickUpColor: '#ef5350',
            scaleMargins: {
                top: 0.05,
                bottom: 0.25, // Leave space for the volume chart below
            },
        });
        // add volume
        volumeSeriesRef.current = chart1.addHistogramSeries({
            color: '#ef5350',
            priceScaleId: 'left',
            scaleMargins: {
                top: 0.75,
                bottom: 0,
            },
        });
        // add baselineseries
        baselineSeriesRef.current = chart1.addBaselineSeries({
            baseValue: {
                type: 'price',
                price: 100, // Default value, actual value will be set from the data file
            },

            bottomLineColor: 'rgba(38, 166, 154, 1)',
            bottomFillColor1: 'rgba(38, 166, 154, 0.28)',
            bottomFillColor2: 'rgba(38, 166, 154, 0.05)',
            topLineColor: 'rgba(239, 83, 80, 1)',
            topFillColor1: 'rgba(239, 83, 80, 0.05)',
            topFillColor2: 'rgba(239, 83, 80, 0.28)',
        });
        lineSeriesRef.current = chart1.addLineSeries({ color: '#2962FF' });
        lineSeriesRef1.current = chart1.addLineSeries({ color: '#FFFF00' });
        lineSeriesRef2.current = chart1.addLineSeries({ color: '#ff8000' });



        //window.addEventListener('resize', handleResize);

  

        const chart2 = createChart(chartContainerRef2.current, {
            width: chartContainerRef2.current.clientWidth,
            height: chartContainerRef2.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85, // These should match the needs of the associated series
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                // mode: 0,
            },
        });
        const chart3 = createChart(chartContainerRef3.current, {
            width: chartContainerRef3.current.clientWidth,
            height: chartContainerRef3.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85, // These should match the needs of the associated series
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                // mode: 0,
            },
        });
        chartRef2.current = chart2;
        seriesRef2.current = chart2.addCandlestickSeries({
            priceScaleId: 'right',
            upColor: '#ef5350',
            downColor: '#26a69a',
            borderVisible: false,
            wickDownColor: '#26a69a',
            wickUpColor: '#ef5350',
            scaleMargins: {
                top: 0.05,
                bottom: 0.25, // Leave space for the volume chart below
            },
        });

        volumeSeriesRef2.current = chart2.addHistogramSeries({
            color: '#ef5350',
            priceScaleId: 'left',
            scaleMargins: {
                top: 0.75,
                bottom: 0,
            },
        });

        chartRef3.current = chart3;
        seriesRef3.current = chart3.addLineSeries({
            color: '#D5A7FF',
            lineWidth: 1,
        });
        const chart4 = createChart(chartContainerRef4.current, {
            width: chartContainerRef4.current.clientWidth,
            height: chartContainerRef4.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85, // These should match the needs of the associated series
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                // mode: 0,
            },
        });
        chartRef4.current = chart4;
        seriesRef4.current = chart4.addLineSeries({
            priceScaleId: 'right',
            color: '#D5A7FF',
            lineWidth: 1,
        });
        volumeSeriesRef4.current = chart4.addHistogramSeries({
            color: '#ef5350',
            priceScaleId: 'left',
            scaleMargins: {
                top: 0.75,
                bottom: 0,
            },
        });
        const chart5 = createChart(chartContainerRef5.current, {
            width: chartContainerRef5.current.clientWidth,
            height: chartContainerRef5.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85, // These should match the needs of the associated series
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                // mode: 0,
            },
        });
        chartRef5.current = chart5;
        seriesRef5.current = chart5.addLineSeries({
            priceScaleId: 'right',
            color: '#D5A7FF',
            lineWidth: 1,
        });
        volumeSeriesRef5.current = chart5.addHistogramSeries({
            color: '#ef5350',
            priceScaleId: 'left',
            scaleMargins: {
                top: 0.75,
                bottom: 0,
            },
        });
        const chart6 = createChart(chartContainerRef6.current, {
            width: chartContainerRef6.current.clientWidth,
            height: chartContainerRef6.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85, // These should match the needs of the associated series
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                // mode: 0,
            },
        });
        chartRef6.current = chart6;
        seriesRef6.current = chart6.addLineSeries({
            color: '#D5A7FF',
            lineWidth: 1,
        });

        const chart7 = createChart(chartContainerRef7.current, {
            width: chartContainerRef7.current.clientWidth,
            height: chartContainerRef7.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
            },
            leftPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.85, // These should match the needs of the associated series
                    bottom: 0,
                },
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
                // mode: 0,
            },
        });
        chartRef7.current = chart7;
        seriesRef7.current = chart7.addLineSeries({
            color: '#D5A7FF',
            lineWidth: 1,
        });
        const chart8 = createChart(chartContainerRef8.current, {
            width: chartContainerRef8.current.clientWidth,
            height: chartContainerRef8.current.clientHeight,
            layout: {
                background: { color: '#222' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: '#444' },
                horzLines: { color: '#444' },
            },
            rightPriceScale: {
                borderColor: '#485c7b',
                scaleMargins: {
                    top: 0.1, // Adjust top margin to avoid clipping the top of the chart
                    bottom: 0.1, // Adjust bottom margin to avoid clipping the bottom of the chart
                },
              
                minValue: 0,
                maxValue: 100,
               
                mode: 1, // Ensure the percentage mode is used
                percentage: true,
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                secondsVisible: false,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
        });
        chartRef8.current = chart8;
        seriesRef8.current = chart8.addAreaSeries({    
            topColor: 'rgba(213, 167, 255, 0.56)',
            bottomColor: 'rgba(213, 167, 255, 0.04)',
            lineColor: '#D5A7FF',
            lineWidth: 2,
    });
    
/*
        seriesRef8.current = chart8.addLineSeries({
            color: '#D5A7FF',
            lineWidth: 1,
        });
        */
/*
        const handleResize2 = () => {
            chart2.applyOptions({
                width: chartContainerRef2.current.clientWidth,
                height: chartContainerRef2.current.clientHeight,
            });
        };
*/
       // window.addEventListener('resize', handleResize2);
        // Synchronize time scale changes
        syncTimeScale(chart1, [ chart2,chart3,chart4,chart5,chart6,chart7,chart8]);
        syncTimeScale(chart2, [ chart1]);
        syncTimeScale(chart3, [ chart1]);
        syncTimeScale(chart4, [ chart1]);
        syncTimeScale(chart5, [ chart1]);
        syncTimeScale(chart6, [ chart1]);
        syncTimeScale(chart7, [ chart1]);
        syncTimeScale(chart8, [ chart1]);
       // syncTimeScale1(chart1,chart2, chart3);
        //syncTimeScale(chart1, chart2);
       // syncTimeScale(chart2, chart1);

       // syncTimeScale1(chart1,chart2, chart3);
        //syncTimeScale(chart3, chart1);
      //  syncTimeScale(chart1, chart4);
/*
        syncTimeScale(chart4, chart1);
        syncTimeScale(chart1, chart4);
        
        syncTimeScale(chart5, chart1);
        syncTimeScale(chart1, chart5);

        syncTimeScale(chart6, chart1);
        syncTimeScale(chart1, chart6);
        */
       // createChartWithConfig(chartContainerRef3, chartRef3, seriesRef3,chartRef,chartRef2);
    //    createChartWithConfig(chartContainerRef4, chartRef4, seriesRef4);
     //   createChartWithConfig(chartContainerRef5, chartRef5, seriesRef5);
    ///    createChartWithConfig(chartContainerRef6, chartRef6, seriesRef6);
     //   createChartWithConfig(chartContainerRef7, chartRef7, seriesRef7);
      //  createChartWithConfig(chartContainerRef8, chartRef8, seriesRef8);
       //createChartWithConfig(chartContainerRef6, chartRef6, seriesRef6,chartRef,chartRef2);
     // if (showAdditionalCharts && !additionalChartsCreated) {
        /*
        if (visibleCharts['資券']) {
        createChartWithConfig(chartContainerRef3, chartRef3, seriesRef3,chartRef,chartRef2);
        createChartWithConfig(chartContainerRef4, chartRef4, seriesRef4,chartRef,chartRef2);
        createChartWithConfig(chartContainerRef5, chartRef5, seriesRef5,chartRef,chartRef2);
        createChartWithConfig(chartContainerRef6, chartRef6, seriesRef6,chartRef,chartRef2);
        createChartWithConfig(chartContainerRef7, chartRef7, seriesRef7,chartRef,chartRef2);
        createChartWithConfig(chartContainerRef8, chartRef8, seriesRef8,chartRef,chartRef2);
        }
        */
      //  syncTimeScale(chart1, chartRef5);
     //   syncTimeScale(chartRef5, chart1);
      //  syncTimeScale(chart1, chartRef6);
      //  syncTimeScale(chartRef6, chart1);
    //    setAdditionalChartsCreated(true);
   // }
   const handleResize = () => {
    
    chart1.applyOptions({
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
    });


    chart2.applyOptions({
        width: chartContainerRef2.current.clientWidth,
        height: chartContainerRef2.current.clientHeight,
    });
    chart3.applyOptions({
        width: chartContainerRef3.current.clientWidth,
        height: chartContainerRef3.current.clientHeight,
    });
    chart4.applyOptions({
        width: chartContainerRef4.current.clientWidth,
        height: chartContainerRef4.current.clientHeight,
    });
    chart5.applyOptions({
        width: chartContainerRef5.current.clientWidth,
        height: chartContainerRef5.current.clientHeight,
    });
  //  console.log(`Resizing chart: ${chartContainerRef6.current.clientHeight}`);
        
    chart6.applyOptions({
        width: chartContainerRef6.current.clientWidth,
        height: chartContainerRef6.current.clientHeight,
    });
    chart7.applyOptions({
        width: chartContainerRef7.current.clientWidth,
        height: chartContainerRef7.current.clientHeight,
    });
    chart8.applyOptions({
        width: chartContainerRef8.current.clientWidth,
        height: chartContainerRef8.current.clientHeight,
    });
   // console.log(`height: ${chartContainerRef6.current.clientHeight}`);
        
    updateLegendPositions();
};
   window.addEventListener('resize', handleResize);
   handleResize();
   function getSeriesDataAtIndex(chart, series, index) {
    const seriesApi = chart._private__seriesMap.get(series);
    if (seriesApi) {
        const dataList = seriesApi._private__data._private__items;
        if (index >= 0 && index < dataList.length) {
            return dataList[index];
        }
    }
    return null;
}
function getSeriesDataAtTime(series, paramTime) {
    const seriesData = series.data();
    //console.log('seriesData')
    //console.log(seriesData)
    if (!seriesData) {
        return null;
    }

    // Convert param.time to a comparable format
   // const paramTimeStr = new Date(paramTime * 1000).toISOString().split('T')[0];

    // Find the matching data point
    const dataPoint = seriesData.find(d => {
       // console.log('d.time')
       // console.log(d.time)
       // console.log('paramTime')
       // console.log(paramTime)
        //const originalTime = new Date(d.time * 1000).toISOString().split('T')[0];
        return d.time === paramTime;
    });

    return dataPoint || null;
}
function removeLastThreeDigits(num) {
    return Math.floor(num / 1000);
}
/*
function convertToChineseNumber(num) {
    if (num >= 100000000) {
        return (num / 100000000).toFixed(2) + '億';
    } else if (num >= 10000) {
        return (num / 10000).toFixed(2) + '萬';
    } else {
        return num.toString();
    }
}
*/
// Example usage
//const originalNumber = 4062673;
//const convertedNumber = removeLastThreeDigits(originalNumber);
//console.log(convertedNumber);  // Output: 4062

  const updateLegend = (param) => {
    
    if (param.time && seriesRef.current && param.seriesData.get(seriesRef.current)) {
        const price = param.seriesData.get(seriesRef.current);
        const price_volume = param.seriesData.get(volumeSeriesRef.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
          //  console.log("param.time:", param.time); // Debugging log
            
        //    const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef2.current, paramTime);
            const dataAtIndexV2 = getSeriesDataAtTime(volumeSeriesRef2.current, paramTime);
            const previousClose1 = getPreviousClose(seriesRef.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef2.current, paramTime);
          
            const dataAtIndex3 = getSeriesDataAtTime(seriesRef3.current, paramTime);
            const dataAtIndex4 = getSeriesDataAtTime(seriesRef4.current, paramTime);
            const dataAtIndex5 = getSeriesDataAtTime(seriesRef5.current, paramTime);
            const dataAtIndex6 = getSeriesDataAtTime(seriesRef6.current, paramTime);
            const dataAtIndex7 = getSeriesDataAtTime(seriesRef7.current, paramTime);
            const dataAtIndex8 = getSeriesDataAtTime(seriesRef8.current, paramTime);
            console.log('dataAtIndex2')
            console.log(dataAtIndex2)
            console.log('dataAtIndex6')
            console.log(dataAtIndex6)
            console.log('dataAtIndexV2')
            console.log(dataAtIndexV2)
            console.log('previousClose2')
            console.log(previousClose2)
            //console.log('seriesRef2.current')
            //console.log(seriesRef2.current)
            //console.log('dataAtIndex3')
          //  console.log(dataAtIndex3)
            
            if (dataAtIndex2 &&dataAtIndex6&&dataAtIndexV2&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                現股
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndexV2 ? removeLastThreeDigits(dataAtIndexV2.value) : 0}張
                    當沖量:${dataAtIndex6 ? (dataAtIndex6.value) : 0}張(${(((dataAtIndex6.value) / removeLastThreeDigits(dataAtIndexV2.value)) * 100).toFixed(2)}%)
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend1(formattedLegend2);
            } else {
                //setLegend('No data');
                setLegend1('No data');
            }
               
            
            if (dataAtIndex3) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
            // const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend3 = `
                資券比
                ${dataAtIndex3.time} 
                : <span style="color: purple;">${(dataAtIndex3.value).toFixed(2)}%</span>
                    
        
                 
                `;
        
                //setLegend(formattedLegend1);
                setLegend3(formattedLegend3);
            } else {
                //setLegend('No data');
              //  setLegend3('No data');
            }  

            if (dataAtIndex4) {
                // const price1 = dataAtIndex1.value;
              //   const price2 = dataAtIndex2.value;
             // const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
             const currentVolume = volumeSeriesRef4.current.data().find(d => d.time === price.time);
             const volumeChange = currentVolume ? currentVolume.value : 0;
       
           //  console.log('price2')
           //  console.log(price2)
            // console.log('price2')
            // console.log(price2)
                 const formattedLegend4 = `
                 融劵 

                 ${dataAtIndex4.time} 
                 增減(張): <span style="color: ${volumeChange >= 0 ? 'red' : 'green'};">${volumeChange >= 0 ? '+' : ''}${volumeChange}張</span>
                 餘額: <span style="color: purple;">${(dataAtIndex4.value)}張</span>
             `;  
         
                 //setLegend(formattedLegend1);
                 setLegend4(formattedLegend4);
             } else {
                 //setLegend('No data');
           //      setLegend4('No data');
             }  
             
             
             if (dataAtIndex5) {
                // const price1 = dataAtIndex1.value;
              //   const price2 = dataAtIndex2.value;
             // const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
             const currentVolume = volumeSeriesRef5.current.data().find(d => d.time === price.time);
             const volumeChange = currentVolume ? currentVolume.value : 0;
       
           //  console.log('price2')
           //  console.log(price2)
            // console.log('price2')
            // console.log(price2)
            const formattedLegend5 = `
            融資 
   
            ${dataAtIndex5.time} 
            增減(張): <span style="color: ${volumeChange >= 0 ? 'red' : 'green'};">${volumeChange >= 0 ? '+' : ''}${volumeChange}張</span>
            餘額: <span style="color: purple;">${(dataAtIndex5.value)}張</span>
        `; 
         
                 //setLegend(formattedLegend1);
                 setLegend5(formattedLegend5);
             } else {
                 //setLegend('No data');
        //         setLegend5('No data');
             } 
             if (dataAtIndex6) {
                // const price1 = dataAtIndex1.value;
              //   const price2 = dataAtIndex2.value;
             // const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
          //   if (volumeSeriesRef6.current) {
          //   const currentVolume = volumeSeriesRef6.current.data().find(d => d.time === price.time);
         //    const volumeChange = currentVolume ? currentVolume.value : 0;
       
           //  console.log('price2')
           //  console.log(price2)
            // console.log('price2')
            // console.log(price2)
            const formattedLegend6 = `
            當沖  
   
            ${dataAtIndex6.time} 
           
            量: <span style="color: purple;">${(dataAtIndex6.value)}張</span>
        `; 
         
                 //setLegend(formattedLegend1);
                 setLegend6(formattedLegend6);
          //   }
             } else {
                 //setLegend('No data');
                 setLegend6('No data');
             }
             if (dataAtIndex7) {
                // const price1 = dataAtIndex1.value;
              //   const price2 = dataAtIndex2.value;
             // const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
          //   if (volumeSeriesRef6.current) {
          //   const currentVolume = volumeSeriesRef6.current.data().find(d => d.time === price.time);
         //    const volumeChange = currentVolume ? currentVolume.value : 0;
       
           //  console.log('price2')
           //  console.log(price2)
            // console.log('price2')
            // console.log(price2)
            function convertToChineseNumber(num) {
                if (num >= 1) {
                    return num.toFixed(2) + '億';
                } else if (num >= 0.0001) {
                    return num.toFixed(2) + '萬';
                } else {
                    return num.toString();
                }
            }
            const formattedLegend7 = `
            轉換  
   
            ${dataAtIndex7.time} 
           
            餘額: <span style="color: purple;">${(convertToChineseNumber(dataAtIndex7.value))}</span>
        `; 
         
                 //setLegend(formattedLegend1);
                 setLegend7(formattedLegend7);
          //   }
             } else {
                 //setLegend('No data');
                 setLegend7('No data');
             } 
             if (dataAtIndex8) {
                // const price1 = dataAtIndex1.value;
              //   const price2 = dataAtIndex2.value;
             // const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
          //   if (volumeSeriesRef6.current) {
          //   const currentVolume = volumeSeriesRef6.current.data().find(d => d.time === price.time);
         //    const volumeChange = currentVolume ? currentVolume.value : 0;
       
           //  console.log('price2')
           //  console.log(price2)
            // console.log('price2')
            // console.log(price2)
           // const adjustedValue = 1 - dataAtIndex8.value; // Perform the calculation here
            const formattedLegend8 = `
            轉換  
                ${dataAtIndex8.time} 
                比例: <span style="color: purple;">${(dataAtIndex8.value).toFixed(2)}%</span>
                
            `;
         
                 //setLegend(formattedLegend1);
                 setLegend8(formattedLegend8);
          //   }
             } else {
                 //setLegend('No data');
                 setLegend8('No data');
             }
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price && previousClose1 !=null) {
         const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
        
           // console.log('currentIndex1')
           // console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);

            const formattedLegend = `
            可轉債 
            ${price.time} 
                開: ${price.open.toFixed(2)} 
                高: ${price.high.toFixed(2)} 
                低: ${price.low.toFixed(2)} 
                收: ${price.close.toFixed(2)} 
                <span style="color: ${price.close >= previousClose1 ? 'red' : 'green'};">
                    ${absoluteChange1} (${percentageChange1})
                </span> 
                量: ${price_volume ? price_volume.value : 0}張
            `;
            setLegend(formattedLegend);
         }
        } else {
         //   setLegend('No data');
        }
    } else {
        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        setLegend1(formattedLegend);
        const formattedLegend3 = `
        資券比
        
        : <span style="color: purple;">--</span>
            

         
        `;
        setLegend3(formattedLegend3);
        const formattedLegend4 = `
        融劵 

        
        增減(張): <span style="color: red">--張</span>
        餘額: <span style="color: purple;">--張</span>
    `;  
        setLegend4(formattedLegend4);
        const formattedLegend5 = `
        融資 

        
        增減(張): <span style="color: red">--張</span>
        餘額: <span style="color: purple;">--張</span>
    `;  
       // const formattedLegend5 = `融劵  增減(張): 0張 餘額: 0張`;
        setLegend5(formattedLegend5);

        const formattedLegend6 = `
        當沖  

        
       
        量: <span style="color: purple;">--張</span>
    `; 
     
             //setLegend(formattedLegend1);
             setLegend6(formattedLegend6);

        const formattedLegend7 = `
            轉換  
    
           
            餘額: <span style="color: purple;">${(0)}</span>
        `; 
         
                 //setLegend(formattedLegend1);
                 setLegend7(formattedLegend7);
                 const formattedLegend8 = `
                 轉換  
                      
                     比例: <span style="color: purple;">--%</span>
                     
                 `;
              
                      //setLegend(formattedLegend1);
                      setLegend8(formattedLegend8);         
    }
};
const updateLegend1 = (param) => {
    
    if (param.time && seriesRef2.current && param.seriesData.get(seriesRef2.current)) {
        const price = param.seriesData.get(seriesRef2.current);
        const price_volume = param.seriesData.get(volumeSeriesRef2.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
         //   console.log("param.time:", param.time); // Debugging log
            
          //  const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex3 = getSeriesDataAtTime(volumeSeriesRef.current, paramTime);
            const previousClose1 = getPreviousClose(seriesRef2.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef.current, paramTime);
            const dataAtIndex6 = getSeriesDataAtTime(seriesRef6.current, paramTime);
            //console.log('dataAtIndex2')
           // console.log(dataAtIndex2)
            //console.log('seriesRef2.current')
            //console.log(seriesRef2.current)
           // console.log('dataAtIndex3')
            //console.log(dataAtIndex3)
            
            if (dataAtIndex2 &&dataAtIndex3&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                可轉債
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndex3 ? dataAtIndex3.value : 0}張
                    
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend(formattedLegend2);
            } else {
                //setLegend('No data');
                setLegend('No data');
            }
           
        
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price &&dataAtIndex6&& previousClose1 !=null) {
         const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
        
          //  console.log('currentIndex1')
          //  console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);

            const formattedLegend = `
            現股
            ${price.time} 
                開: ${price.open.toFixed(2)} 
                高: ${price.high.toFixed(2)} 
                低: ${price.low.toFixed(2)} 
                收: ${price.close.toFixed(2)} 
                <span style="color: ${price.close >= previousClose1 ? 'red' : 'green'};">
                    ${absoluteChange1} (${percentageChange1})
                </span> 
                量: ${price_volume ? removeLastThreeDigits(price_volume.value) : 0}張
                當沖量:${dataAtIndex6 ? (dataAtIndex6.value) : 0}張(${(((dataAtIndex6.value) / removeLastThreeDigits(price_volume.value)) * 100).toFixed(2)}%)
            `;
            setLegend1(formattedLegend);
         }
        } else {
            setLegend1('No data');
        }
    } else {
        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        setLegend1(formattedLegend);
    }
};

////
const updateLegend3 = (param) => {
    
    if (param.time && seriesRef3.current && param.seriesData.get(seriesRef3.current)) {
        const price = param.seriesData.get(seriesRef3.current);
      //  const price_volume = param.seriesData.get(volumeSeriesRef2.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
           // console.log("param.time:", param.time); // Debugging log
            
            //const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex3 = getSeriesDataAtTime(volumeSeriesRef.current, paramTime);
          //  const previousClose1 = getPreviousClose(seriesRef2.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef.current, paramTime);
          
      
            
            if (dataAtIndex2 &&dataAtIndex3&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                可轉債
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndex3 ? dataAtIndex3.value : 0}張
                    
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend(formattedLegend2);
            } else {
                //setLegend('No data');
                setLegend('No data');
            }
           
        
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price ) {
      //   const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
        
           // console.log('currentIndex1')
          //  console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);

         const formattedLegend3 = `
         資券比
         ${price.time} 
         :  <span style="color: purple;">${(price.value).toFixed(2)}%</span>
             
 
          
         `;
 
         //setLegend(formattedLegend1);
         setLegend3(formattedLegend3);
           
         }
        } else {
            setLegend3('No data');
        }
    } else {
      //  const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
       // setLegend(formattedLegend);
        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        const formattedLegend3 = `
        資券比
        
        : <span style="color: purple;">--</span>
            

         
        `;
       // const formattedLegend5 = `融劵  增減(張): 0張 餘額: 0張`;
        setLegend3(formattedLegend3);
       
    }
};

const updateLegend4 = (param) => {
    
    if (param.time && seriesRef4.current && param.seriesData.get(seriesRef4.current)) {
        const price = param.seriesData.get(seriesRef4.current);
      //  const price_volume = param.seriesData.get(volumeSeriesRef2.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
       //     console.log("param.time:", param.time); // Debugging log
            
            //const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex3 = getSeriesDataAtTime(volumeSeriesRef.current, paramTime);
          //  const previousClose1 = getPreviousClose(seriesRef2.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef.current, paramTime);
          
   
            
            if (dataAtIndex2 &&dataAtIndex3&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                可轉債
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndex3 ? dataAtIndex3.value : 0}張
                    
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend(formattedLegend2);
            } else {
                //setLegend('No data');
                setLegend('No data');
            }
           
        
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price ) {
      //   const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
      const currentVolume = volumeSeriesRef4.current.data().find(d => d.time === price.time);
      const volumeChange = currentVolume ? currentVolume.value : 0;

           // console.log('currentIndex1')
          //  console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);

         const formattedLegend4 = `
         融劵 

         ${price.time} 
         增減(張): <span style="color: ${volumeChange >= 0 ? 'red' : 'green'};">${volumeChange >= 0 ? '+' : ''}${volumeChange}張</span>
         餘額: <span style="color: purple;">${(price.value)}張</span>
     `;   
 
          
       
 
         //setLegend(formattedLegend1);
         setLegend4(formattedLegend4);
           
         }
        } else {
            setLegend4('No data');
        }
    } else {
       // const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
      //  setLegend1(formattedLegend);
        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        const formattedLegend4 = `
        融劵 

        
        增減(張): <span style="color: red">--張</span>
        餘額: <span style="color: purple;">--張</span>
    `;  
       // const formattedLegend5 = `融劵  增減(張): 0張 餘額: 0張`;
        setLegend4(formattedLegend4);
    }
};

///
const updateLegend5 = (param) => {
    
    if (param.time && seriesRef5.current && param.seriesData.get(seriesRef5.current)) {
        const price = param.seriesData.get(seriesRef5.current);
      //  const price_volume = param.seriesData.get(volumeSeriesRef2.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
         //   console.log("param.time:", param.time); // Debugging log
            
         //   const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex3 = getSeriesDataAtTime(volumeSeriesRef.current, paramTime);
          //  const previousClose1 = getPreviousClose(seriesRef2.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef.current, paramTime);
          
       
            
            if (dataAtIndex2 &&dataAtIndex3&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                可轉債
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndex3 ? dataAtIndex3.value : 0}張
                    
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend(formattedLegend2);
            } else {
                //setLegend('No data');
                const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
                setLegend(formattedLegend);
              // setLegend('No data');
            }
           
        
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price ) {
      //   const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
      const currentVolume = volumeSeriesRef5.current.data().find(d => d.time === price.time);
      const volumeChange = currentVolume ? currentVolume.value : 0;

           // console.log('currentIndex1')
          //  console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);

         const formattedLegend5 = `
         融資 

         ${price.time} 
         增減(張): <span style="color: ${volumeChange >= 0 ? 'red' : 'green'};">${volumeChange >= 0 ? '+' : ''}${volumeChange}張</span>
         餘額: <span style="color: purple;">${(price.value)}張</span>
     `;   
 
          
       
 
         //setLegend(formattedLegend1);
         setLegend5(formattedLegend5);
           
         }
        } else {
            const formattedLegend5 = `融資  增減(張): 0張 餘額: 0張`;
            setLegend5(formattedLegend5);
            //setLegend5('No data');
        }
    } else {
       // const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
       // setLegend1(formattedLegend);
       // setLegend('No data');
        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        const formattedLegend5 = `
        融資 

        
        增減(張): <span style="color: red">--張</span>
        餘額: <span style="color: purple;">--張</span>
    `;  
       // const formattedLegend5 = `融劵  增減(張): 0張 餘額: 0張`;
        setLegend5(formattedLegend5);
        
    }
};

const updateLegend6 = (param) => {
    
    if (param.time && seriesRef6.current && param.seriesData.get(seriesRef6.current)) {
        const price = param.seriesData.get(seriesRef6.current);
      //  const price_volume = param.seriesData.get(volumeSeriesRef2.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
           // console.log("param.time:", param.time); // Debugging log
            
           // const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex3 = getSeriesDataAtTime(volumeSeriesRef.current, paramTime);
          //  const previousClose1 = getPreviousClose(seriesRef2.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef.current, paramTime);
          
           // console.log('dataAtIndex2')
           // console.log(dataAtIndex2)
           // console.log('seriesRef2.current')
           // console.log(seriesRef2.current)
         //   console.log('dataAtIndex3')
       //     console.log(dataAtIndex3)
            
            if (dataAtIndex2 &&dataAtIndex3&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                可轉債
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndex3 ? dataAtIndex3.value : 0}張
                    
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend(formattedLegend2);
            } else {
                //setLegend('No data');
                const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
                setLegend(formattedLegend);
              // setLegend('No data');
            }
           
        
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price ) {
      //   const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
    //  const currentVolume = volumeSeriesRef6.current.data().find(d => d.time === price.time);
    //  const volumeChange = currentVolume ? currentVolume.value : 0;

           // console.log('currentIndex1')
          //  console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);

         const formattedLegend6 = `
         當沖  

         ${price.time} 
        
         量: <span style="color: purple;">${(price.value)}張</span>
     `; 
      
              //setLegend(formattedLegend1);
              setLegend6(formattedLegend6);
           
         }
        } else {
            const formattedLegend6 = `融資  增減(張): 0張 餘額: 0張`;
            setLegend6(formattedLegend6);
            //setLegend5('No data');
        }
    } else {
       // const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
       // setLegend1(formattedLegend);
       // setLegend('No data');
        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        const formattedLegend6 = `
        當沖  

        
       
        量: <span style="color: purple;">--張</span>
    `; 
     
             //setLegend(formattedLegend1);
             setLegend6(formattedLegend6);
        
    }
};

const updateLegend7 = (param) => {
    
    if (param.time && seriesRef7.current && param.seriesData.get(seriesRef7.current)) {
        const price = param.seriesData.get(seriesRef7.current);
      //  const price_volume = param.seriesData.get(volumeSeriesRef2.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
            //console.log("param.time:", param.time); // Debugging log
            
            //const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex3 = getSeriesDataAtTime(volumeSeriesRef.current, paramTime);
          //  const previousClose1 = getPreviousClose(seriesRef2.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef.current, paramTime);
          
           // console.log('dataAtIndex2')
          //  console.log(dataAtIndex2)
           // console.log('seriesRef2.current')
            //console.log(seriesRef2.current)
           // console.log('dataAtIndex3')
           // console.log(dataAtIndex3)
            
            if (dataAtIndex2 &&dataAtIndex3&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                可轉債
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndex3 ? dataAtIndex3.value : 0}張
                    
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend(formattedLegend2);
            } else {
                //setLegend('No data');
                const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
                setLegend(formattedLegend);
              // setLegend('No data');
            }
           
        
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price ) {
      //   const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
    //  const currentVolume = volumeSeriesRef6.current.data().find(d => d.time === price.time);
    //  const volumeChange = currentVolume ? currentVolume.value : 0;

           // console.log('currentIndex1')
          //  console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);
         function convertToChineseNumber(num) {
            if (num >= 1) {
                return num.toFixed(2) + '億';
            } else if (num >= 0.0001) {
                return num.toFixed(2) + '萬';
            } else {
                return num.toString();
            }
        }
         const formattedLegend7 = `
         轉換  

         ${price.time} 
        
         餘額: <span style="color: purple;">${(convertToChineseNumber(price.value))}</span>
     `; 
      
              //setLegend(formattedLegend1);
              setLegend7(formattedLegend7);
           
         }
        } else {
         //   const formattedLegend6 = `融資  增減(張): 0張 餘額: 0張`;
         //   setLegend6(formattedLegend6);
           
        }
    } else {

        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        const formattedLegend7 = `
            轉換  
    
           
            餘額: <span style="color: purple;">${(0)}</span>
        `; 
         
              
                 setLegend7(formattedLegend7);
        
    }
};

const updateLegend8 = (param) => {
    
    if (param.time && seriesRef8.current && param.seriesData.get(seriesRef8.current)) {
        const price = param.seriesData.get(seriesRef8.current);
      //  const price_volume = param.seriesData.get(volumeSeriesRef2.current);
       // const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);
        if (price) {
            //console.log("param.time:", param.time); // Debugging log
            
            //const currentIndex = seriesData.findIndex(d => d.time === param.time);
                        //   console.log("currentIndex:", currentIndex); // Debugging log
            //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
   
             //  console.log('currentIndex')
             //console.log(currentIndex)
            // console.log(param)
            // const index = currentIndex;
            const paramTime = param.time;
            
           // const dataAtIndex1 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex2 = getSeriesDataAtTime(seriesRef.current, paramTime);
            const dataAtIndex3 = getSeriesDataAtTime(volumeSeriesRef.current, paramTime);
          //  const previousClose1 = getPreviousClose(seriesRef2.current, paramTime);
            const previousClose2 = getPreviousClose(seriesRef.current, paramTime);
          
           // console.log('dataAtIndex2')
           // console.log(dataAtIndex2)
           // console.log('seriesRef2.current')
           // console.log(seriesRef2.current)
          //  console.log('dataAtIndex3')
          //  console.log(dataAtIndex3)
            
            if (dataAtIndex2 &&dataAtIndex3&& previousClose2 !=null) {
               // const price1 = dataAtIndex1.value;
             //   const price2 = dataAtIndex2.value;
             const { absoluteChange: absoluteChange2, percentageChange: percentageChange2 } = calculateAndFormatChange(dataAtIndex2.close, previousClose2);
    
          //  console.log('price2')
          //  console.log(price2)
           // console.log('price2')
           // console.log(price2)
                const formattedLegend2 = `
                可轉債
                ${dataAtIndex2.time} 
                    開: ${dataAtIndex2.open.toFixed(2)} 
                    高: ${dataAtIndex2.high.toFixed(2)} 
                    低: ${dataAtIndex2.low.toFixed(2)} 
                    收: ${dataAtIndex2.close.toFixed(2)}
                    <span style="color: ${dataAtIndex2.close >= previousClose2 ? 'red' : 'green'};">
                    ${absoluteChange2} (${percentageChange2})
                    </span> 
                    量: ${dataAtIndex3 ? dataAtIndex3.value : 0}張
                    
                </span> 
                `;
        
                //setLegend(formattedLegend1);
                setLegend(formattedLegend2);
            } else {
                //setLegend('No data');
                const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: 0`;
                setLegend(formattedLegend);
              // setLegend('No data');
            }
           
        
 //console.log('dataAtIndex');
 //console.log(dataAtIndex2);

         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log
         if (price ) {
      //   const { absoluteChange: absoluteChange1, percentageChange: percentageChange1 } = calculateAndFormatChange(price.close, previousClose1);
    //  const currentVolume = volumeSeriesRef6.current.data().find(d => d.time === price.time);
    //  const volumeChange = currentVolume ? currentVolume.value : 0;

           // console.log('currentIndex1')
          //  console.log(currentIndex)
           // const previousClose = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
         //   const { absoluteChange, percentageChange } = calculateAndFormatChange(price.close, previousClose);

        const formattedLegend8 = `
        轉換  
            ${price.time} 
            比例: <span style="color: purple;">${(price.value).toFixed(2)}%</span>
            
        `;
     
             
             setLegend8(formattedLegend8);
           
         }
        } else {
          //  const formattedLegend8 = `融資  增減(張): 0張 餘額: 0張`;
         //   setLegend6(formattedLegend6);
           
        }
    } else {

        const formattedLegend = `開: 0 高: 0 低: 0 收: 0 量: --張`;
        setLegend(formattedLegend);
        const formattedLegend8 = `
        轉換  
             
            比例: <span style="color: purple;">--%</span>
            
        `;
     
            
             setLegend8(formattedLegend8); 
        
    }
};
const updateLegend2 = (param) => {
    
    if (param.time && seriesRef2.current && param.seriesData.get(seriesRef2.current)) {
        const price1 = param.seriesData.get(seriesRef2.current);
        const price_volume1 = param.seriesData.get(volumeSeriesRef2.current);

        if (price1) {
            console.log("param.time:", param.time); // Debugging log
            
            const currentIndex = seriesData.findIndex(d => d.time === param.time);
         //   console.log("currentIndex:", currentIndex); // Debugging log
         //   console.log("Matching seriesData entry:", seriesData[currentIndex]); // Debugging log

          //  console.log('currentIndex')
            console.log(currentIndex)
            const previousClose1 = getPreviousClose(currentIndex);
        //    console.log('price.close, previousClose')
        //    console.log(price.close)
       //     console.log(previousClose)
                 console.log('price')
           console.log(price1)
            const { absoluteChange1, percentageChange1 } = calculateAndFormatChange(price1.close, previousClose1);

            const formattedLegend1 = `
            ${price1.time} 
                開: ${price1.open.toFixed(2)} 
                高: ${price1.high.toFixed(2)} 
                低: ${price1.low.toFixed(2)} 
                收: ${price1.close.toFixed(2)} 
                <span style="color: ${price1.close >= previousClose1 ? 'red' : 'green'};">
                    ${absoluteChange1} (${percentageChange1})
                </span> 
                量: ${price_volume1 ? price_volume1.value : 0}
            `;
            setLegend1(formattedLegend1);
        } else {
            setLegend1('No data');
        }
    } else {
        const formattedLegend1 = `開: 0 高: 0 低: 0 收: 0 量: 0`;
        setLegend1(formattedLegend1);
    }
};
// Subscribe to crosshair move for updating the legend

  //chart1.subscribeCrosshairMove(updateLegend);
 // chart2.subscribeCrosshairMove(updateLegend1);
        chart1.subscribeCrosshairMove((param) => {
            if (param.seriesData && baselineSeriesRef.current && param.seriesData.get(baselineSeriesRef.current)) {
                const price_parity = param.seriesData.get(baselineSeriesRef.current);
                if (price_parity) {
                    const formattedLegend_parity = `  ${price_parity.value.toFixed(2)}`;
                    setLegend_parity(formattedLegend_parity);
                } else {
                    setLegend_parity('No data');
                }
            } else {
                const formattedLegend_parity = ` 0 `;
                setLegend_parity(formattedLegend_parity);
            }
        });
        
        chart1.subscribeCrosshairMove((param) => {
            if (param.seriesData && lineSeriesRef.current && param.seriesData.get(lineSeriesRef.current)) {
                const price_premium = param.seriesData.get(lineSeriesRef.current);
                if (price_premium) {
                    const formattedLegend_premium = `  ${price_premium.value.toFixed(2)}`;
                    setLegend_premium(formattedLegend_premium);
                } else {
                    setLegend_premium('No data');
                }
            } else {
                const formattedLegend_premium = ` 0 `;
                setLegend_premium(formattedLegend_premium);
            }
        });
        chart1.subscribeCrosshairMove((param) => {
            if (param.seriesData && lineSeriesRef1.current && param.seriesData.get(lineSeriesRef1.current)) {
                const price_theoretical_price = param.seriesData.get(lineSeriesRef1.current);
                if (price_theoretical_price) {
                    const formattedLegend_theoretical_price = `  ${price_theoretical_price.value.toFixed(2)}`;
                    setLegend_theoretical_price(formattedLegend_theoretical_price);
                } else {
                    setLegend_theoretical_price('No data');
                }
            } else {
                const formattedLegend_theoretical_price = ` 0 `;
                setLegend_theoretical_price(formattedLegend_theoretical_price);
            }
        });

        chart1.subscribeCrosshairMove((param) => {
            if (param.seriesData && lineSeriesRef2.current && param.seriesData.get(lineSeriesRef2.current)) {
                const price_theoretical_price1 = param.seriesData.get(lineSeriesRef2.current);
                if (price_theoretical_price1) {
                    const formattedLegend_theoretical_price1 = `  ${price_theoretical_price1.value.toFixed(2)}`;
                    setLegend_theoretical_price1(formattedLegend_theoretical_price1);
                } else {
                    setLegend_theoretical_price1('No data');
                }
            } else {
                const formattedLegend_theoretical_price1 = ` 0 `;
                setLegend_theoretical_price1(formattedLegend_theoretical_price1);
            }
        });

        chart1.subscribeCrosshairMove((param) => {
            if (param.seriesData && volumeSeriesRef.current && param.seriesData.get(volumeSeriesRef.current)) {
                const price_volume = param.seriesData.get(volumeSeriesRef.current);
                if (price_volume) {
                    const formattedLegend_volume = ` ${price_volume.value}`;
                    setLegend_volume(formattedLegend_volume);
                } else {
                    setLegend_volume('No data');
                }
            } else {
                const formattedLegend_volume = `0`;
                setLegend_volume(formattedLegend_volume);
            }
        });


        // Synchronize crosshairs
        chart1.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef.current, param);
            updateLegend(param);
            syncCrosshair(chart2, seriesRef2.current, dataPoint);
            syncCrosshair(chart3, seriesRef3.current, dataPoint);
            syncCrosshair(chart4, seriesRef4.current, dataPoint);
            syncCrosshair(chart5, seriesRef5.current, dataPoint);
            syncCrosshair(chart6, seriesRef6.current, dataPoint);
            syncCrosshair(chart7, seriesRef7.current, dataPoint);
            syncCrosshair(chart8, seriesRef8.current, dataPoint);
        });

        chart2.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef2.current, param);
            updateLegend1(param);
            syncCrosshair(chart1, seriesRef.current, dataPoint);

        });
        
        chart3.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef3.current, param);
            updateLegend3(param);
            syncCrosshair(chart1, seriesRef.current, dataPoint);

        });

        chart4.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef4.current, param);
            updateLegend4(param);
            syncCrosshair(chart1, seriesRef.current, dataPoint);

        });
        chart5.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef5.current, param);
            updateLegend5(param);
            syncCrosshair(chart1, seriesRef.current, dataPoint);

        });
        chart6.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef6.current, param);
            updateLegend6(param);
            syncCrosshair(chart1, seriesRef.current, dataPoint);

        });
        chart7.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef7.current, param);
            updateLegend7(param);
            syncCrosshair(chart1, seriesRef.current, dataPoint);

        });
        chart8.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef8.current, param);
            updateLegend8(param);
            syncCrosshair(chart1, seriesRef.current, dataPoint);

        });
       // chartContainerRef3, chartRef3, seriesRef3,chartRef,chartRef2);
          //  if (subCharts.marginRatio && !chartRef3.current) {
         //       createChartWithConfig(chartContainerRef3, chartRef3, seriesRef3);
         //   }
        
         chart1.timeScale().scrollToRealTime();
         chart2.timeScale().scrollToRealTime();
         chart3.timeScale().scrollToRealTime();
         chart4.timeScale().scrollToRealTime();
         chart5.timeScale().scrollToRealTime();
         chart6.timeScale().scrollToRealTime();
         chart7.timeScale().scrollToRealTime();
         chart8.timeScale().scrollToRealTime();
        setLoading(false);
        return () => {
            if (chartRef.current) {
                chart1.remove();
            }
            if (chartRef2.current) {
                chart2.remove();
            }
            if (chartRef3.current) {
                chart3.remove();
            }
            if (chartRef4.current) {
                chart4.remove();
            }
            if (chartRef5.current) {
                chart5.remove();
            }
            if (chartRef6.current) {
                chart6.remove();
                
            }
            if (chartRef7.current) {
                chart7.remove();
                
            }
            if (chartRef8.current) {
                chart8.remove();
                
            }
            //if (additionalChartsCreated) {
         //   removeChart(chartRef3);
        //    removeChart(chartRef4);
         //   removeChart(chartRef5);
            //removeChart(chartRef6);
           // removeChart(chartRef7);
          //  removeChart(chartRef8);
         //   }
            window.removeEventListener('resize', handleResize);
           // window.removeEventListener('resize', handleResize2);
        };

    }, [ ]);

    useEffect(() => {
        if (baselineSeriesRef.current) {
            baselineSeriesRef.current.applyOptions({ visible: lineVisible_parity });
        }
    }, [lineVisible_parity]);
    
    useEffect(() => {
        if (lineSeriesRef.current) {
            lineSeriesRef.current.applyOptions({ visible: lineVisible_premium });
        }
    }, [lineVisible_premium]);
    
    useEffect(() => {
        if (lineSeriesRef1.current) {
            lineSeriesRef1.current.applyOptions({ visible: lineVisible_theoretical_price });
        }
    }, [lineVisible_theoretical_price]);
    
    useEffect(() => {
        if (lineSeriesRef2.current) {
            lineSeriesRef2.current.applyOptions({ visible: lineVisible_theoretical_price1 });
        }
    }, [lineVisible_theoretical_price1]);
    
    useEffect(() => {
        if (volumeSeriesRef.current) {
            volumeSeriesRef.current.applyOptions({ visible: lineVisible_volume });
        }
    }, [lineVisible_volume]);

    useEffect(() => {
        if (!symbol) {
            return;
        }
        const modifiedSymbol = symbol.slice(0, -1); // Assumes the symbol needs to be shortened by one character

        const fetchData = async () => {
            setError(''); // Clear previous errors
            setLoading(true); 
            try {
                const fetchOhlcData = async () => {
                // Fetching candlestick data
                const ohlcResponse = await fetch(`https://cb.gutalk.ai/new_ohlc_data1/${symbol}_daily_ohlc.csv`);
                if (!ohlcResponse.ok) {
                    throw new Error(`HTTP error! Status: ${ohlcResponse.status}`);
                }
              //  const ohlcData = await ohlcResponse.text();
              return await ohlcResponse.text();
            };

                // Fetching parity data for baseline
                const parityResponse = await fetch(`https://cb.gutalk.ai/cb_parity_data/${symbol}_daily_parity.csv`);
                if (!parityResponse.ok) {
                    throw new Error(`HTTP error! Status: ${parityResponse.status}`);
                }
                const parityData = await parityResponse.text();

                // Fetching premium data for baseline
                const premiumResponse = await fetch(`https://cb.gutalk.ai/cb_premium/${symbol}_daily_premium.csv`);
                if (!premiumResponse.ok) {
                    throw new Error(`HTTP error! Status: ${premiumResponse.status}`);
                }
                const premiumData = await premiumResponse.text();

                // Fetching theoretical price 20 for baseline
                const tp02Response = await fetch(`https://cb.gutalk.ai/theoretical_price_20/${symbol}_theoretical_price.csv`);
                if (!tp02Response.ok) {
                    throw new Error(`HTTP error! Status: ${tp02Response.status}`);
                }
                const tp02Data = await tp02Response.text();
                // Fetching theoretical price hv for baseline
                const tphvResponse = await fetch(`https://cb.gutalk.ai/theoretical_price_hv/${symbol}_theoretical_price.csv`);
                if (!tphvResponse.ok) {
                    throw new Error(`HTTP error! Status: ${tphvResponse.status}`);
                }
                const tphvData = await tphvResponse.text();

                // 現股
                const fetchOhlcData1 = async () => {
                const ohlc4response = await fetch(`https://cb.gutalk.ai/new_ohlc_data1/${modifiedSymbol}_daily_ohlc.csv`);
                if (!ohlc4response.ok) {
                    throw new Error(`HTTP error! Status: ${ohlc4response.status}`);
                }
               // const csvData = await ohlc4response.text();
                return await ohlc4response.text();
            };
                // Fetching subchart data
              //  const fetchmarginRatio = async () => {
                const marginRatioResponse = await fetch(`https://cb.gutalk.ai/margin_ratio/${modifiedSymbol}_margin_ratio.csv`);
                if (!marginRatioResponse.ok) {
                    throw new Error(`HTTP error! Status: ${marginRatioResponse.status}`);
                }
                const marginRatioData = await marginRatioResponse.text();
              //return await marginRatioResponse.text();
          //  };
                const marginLendingResponse = await fetch(`https://cb.gutalk.ai/margin_lending/${modifiedSymbol}_margin_lending.csv`);
                if (!marginLendingResponse.ok) {
                    throw new Error(`HTTP error! Status: ${marginLendingResponse.status}`);
                }
                const marginLendingData = await marginLendingResponse.text();

                const marginBorrowingResponse = await fetch(`https://cb.gutalk.ai/margin_borrowing/${modifiedSymbol}_margin_borrowing.csv`);
                if (!marginBorrowingResponse.ok) {
                    throw new Error(`HTTP error! Status: ${marginBorrowingResponse.status}`);
                }
                const marginBorrowingData = await marginBorrowingResponse.text();

                const intradayTradeResponse = await fetch(`https://cb.gutalk.ai/intraday_trade/${modifiedSymbol}_intraday_trade.csv`);
                if (!intradayTradeResponse.ok) {
                    throw new Error(`HTTP error! Status: ${intradayTradeResponse.status}`);
                }
                const intradayTradeData = await intradayTradeResponse.text();

                const convert_valueResponse = await fetch(`https://cb.gutalk.ai/轉換餘額.csv`);
                if (!convert_valueResponse.ok) {
                    throw new Error(`HTTP error! Status: ${convert_valueResponse.status}`);
                }
                const convert_valueData = await convert_valueResponse.text();

                const convert_ratioResponse = await fetch(`https://cb.gutalk.ai/轉換比例.csv`);
                if (!convert_ratioResponse.ok) {
                    throw new Error(`HTTP error! Status: ${convert_ratioResponse.status}`);
                }
                const convert_ratioData = await convert_ratioResponse.text();

                const [
                    ohlcData,
                    csvData,
                   
                ] = await Promise.all([
                    fetchOhlcData(),
                    fetchOhlcData1(),
              

                  
                ]);
                Papa.parse(ohlcData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (ohlcResults) => {
                        if (ohlcResults.errors.length) {
                            throw new Error('Error parsing OHLC CSV data');
                        }
                        if (!ohlcResults.data.length) {
                            throw new Error('No OHLC data available');
                        }

                        const candlestickData = ohlcResults.data.map((item) => ({
                            time: item.time,
                            open: parseFloat(item.open),
                            high: parseFloat(item.high),
                            low: parseFloat(item.low),
                            close: parseFloat(item.close),
                        }));

                        const chart1Times = new Set(candlestickData.map((data) => data.time));
                        const volumeData = ohlcResults.data.map((item) => ({
                            time: item.time,
                            value: parseInt(item.volume), // Ensuring division by 10
                            color: item.close > item.open ? '#26a69a' : '#ef5350',
                        }));

                        seriesRef.current.setData(candlestickData);
                        volumeSeriesRef.current.setData(volumeData);
                        setSeriesData(candlestickData);
                        // Add and set data for MA series
                        // Managing SMA lines
                        updateSMALines(candlestickData, 5, ma5SeriesRef);
                        updateSMALines(candlestickData, 10, ma10SeriesRef);
                        updateSMALines(candlestickData, 20, ma20SeriesRef);
                        updateSMALines(candlestickData, 60, ma60SeriesRef);

                        Papa.parse(csvData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: (results) => {
                                if (results.errors.length) {
                                    throw new Error('Error parsing CSV data');
                                }
                                if (!results.data.length) {
                                    throw new Error('No data available');
                                }

                                const candlestickData2 = results.data.map((item) => ({
                                    time: item.time,
                                    open: parseFloat(item.open),
                                    high: parseFloat(item.high),
                                    low: parseFloat(item.low),
                                    close: parseFloat(item.close),
                                }));
                                //setChartData2(candlestickData2);
                                // Assuming chart2Data is the data array for chart2
                                const filteredChart2Data = candlestickData2.filter((data) => chart1Times.has(data.time));
                                const volumeData2 = results.data.map((item) => ({
                                    time: item.time,
                                    value: parseInt(item.volume), // Ensuring division by 10
                                    color: item.close > item.open ? '#26a69a' : '#ef5350',
                                }));
                                const filteredVol2Data = volumeData2.filter((data) => chart1Times.has(data.time));
                                seriesRef2.current.setData(filteredChart2Data);
                                volumeSeriesRef2.current.setData(filteredVol2Data);

                                // SMA
                                updateSMALines2(filteredChart2Data, 5, ma5SeriesRef2);
                                updateSMALines2(filteredChart2Data, 10, ma10SeriesRef2);
                                updateSMALines2(filteredChart2Data, 20, ma20SeriesRef2);
                                updateSMALines2(filteredChart2Data, 60, ma60SeriesRef2);
                            },
                            error: (error2) => {
                                throw error2;
                            },
                        });
                        Papa.parse(marginRatioData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: (marginRatioResults) => {
                                if (marginRatioResults.errors.length) {
                                    throw new Error('Error parsing margin ratio CSV data');
                                }
                                if (!marginRatioResults.data.length) {
                                    throw new Error('No margin ratio data available');
                                }
                        
                                const lineData = marginRatioResults.data.map((item) => ({
                                    time: item.date,
                                    value: parseFloat(item[modifiedSymbol])*100,
                                }));
                                const filteredChart3Data = lineData.filter((data) => chart1Times.has(data.time));
                             //   console.log(lineData);
                                if (seriesRef3.current) {
                                    seriesRef3.current.setData(filteredChart3Data);
                                }
                            },
                            error: (error) => {
                                throw error;
                            },
                        });

                        Papa.parse(marginLendingData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: (marginLendingResults) => {
                                if (marginLendingResults.errors.length) {
                                    throw new Error('Error parsing margin lending CSV data');
                                }
                                if (!marginLendingResults.data.length) {
                                    throw new Error('No margin lending data available');
                                }
        
                                const lineData = marginLendingResults.data.map((item) => ({
                                    time: item.date,
                                    value: parseFloat(item[modifiedSymbol]),
                                }));
                                const volumeData4 = lineData.map((item, index) => {
                                    const prevItem = lineData[index - 1];
                                    const volumeChange = prevItem ? item.value - prevItem.value : 0;
                                
                                    return {
                                        time: item.time,
                                        value: volumeChange,
                                        color: volumeChange >= 0 ? '#ef5350' : '#26a69a' , // Green for positive, red for negative
                                    };
                                });
                                
                                //console.log('volumeData4')
                             //   console.log(volumeData4)
                                const filteredChart4Data = lineData.filter((data) => chart1Times.has(data.time));
                                const filteredVol4Data= volumeData4.filter((data) => chart1Times.has(data.time));
                               
                            //    console.log(lineData);
                                if (seriesRef4.current) {
                                    seriesRef4.current.setData(filteredChart4Data);
                                    volumeSeriesRef4.current.setData(filteredVol4Data);
                                }
                            },
                            error: (error) => {
                                throw error;
                            },
                        });
        
                        Papa.parse(marginBorrowingData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: (marginBorrowingResults) => {
                                if (marginBorrowingResults.errors.length) {
                                    throw new Error('Error parsing margin borrowing CSV data');
                                }
                                if (!marginBorrowingResults.data.length) {
                                    throw new Error('No margin borrowing data available');
                                }
        
                                const lineData = marginBorrowingResults.data.map((item) => ({
                                    time: item.date,
                                    value: parseFloat(item[modifiedSymbol]),
                                }));
                                //console.log('marginBorrowingData')
                               // console.log(lineData);
                                const volumeData5 = lineData.map((item, index) => {
                                    const prevItem = lineData[index - 1];
                                    const volumeChange = prevItem ? item.value - prevItem.value : 0;
                                
                                    return {
                                        time: item.time,
                                        value: volumeChange,
                                        color: volumeChange >= 0 ? '#ef5350' : '#26a69a' , // Green for positive, red for negative
                                    };
                                });
                                
                                //console.log('volumeData4')
                             //   console.log(volumeData4)
                             
                                
                                const filteredChart5Data = lineData.filter((data) => chart1Times.has(data.time));
                                const filteredVol5Data= volumeData5.filter((data) => chart1Times.has(data.time));
                                if (seriesRef5.current) {
                                    seriesRef5.current.setData(filteredChart5Data);
                                    volumeSeriesRef5.current.setData(filteredVol5Data);
                                }
                            },
                            error: (error) => {
                                throw error;
                            },
                        });
        
                        Papa.parse(intradayTradeData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: (intradayTradeResults) => {
                                if (intradayTradeResults.errors.length) {
                                    throw new Error('Error parsing intraday trade CSV data');
                                }
                                if (!intradayTradeResults.data.length) {
                                    throw new Error('No intraday trade data available');
                                }
        
                                const lineData = intradayTradeResults.data.map((item) => ({
                                    time: item.date,
                                    value: Math.floor(parseFloat(item[modifiedSymbol]) / 1000),
                                }));
                                /*
                                const volumeData6 = lineData.map((item, index) => {
                                    const prevItem = lineData[index - 1];
                                    const volumeChange = prevItem ? item.value - prevItem.value : 0;
                                
                                    return {
                                        time: item.time,
                                        value: volumeChange,
                                        color: volumeChange >= 0 ? '#ef5350' : '#26a69a' , // Green for positive, red for negative
                                    };
                                });
                                */
                              //  console.log('intradayTradeResults')
                             //   console.log(lineData);
                                const filteredChart6Data = lineData.filter((data) => chart1Times.has(data.time));
                               // const filteredVol6Data= volumeData6.filter((data) => chart1Times.has(data.time));
                      
                                if (seriesRef6.current) {
                                    seriesRef6.current.setData(filteredChart6Data);
                                //    volumeSeriesRef6.current.setData(filteredVol6Data);
                                }
                            },
                            error: (error) => {
                                throw error;
                            },
                        });


                        Papa.parse(convert_valueData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: (convert_valueResults) => {
                                if (convert_valueResults.errors.length) {
                                    throw new Error('Error parsing intraday trade CSV data');
                                }
                                if (!convert_valueResults.data.length) {
                                    throw new Error('No intraday trade data available');
                                }
        
                                const lineData = convert_valueResults.data.map((item) => ({
                                    time: item.date,
                                    value: parseFloat(item[symbol])/100000000,
                                    
                                }));
                               // console.log('lineData7');
                              //  console.log(lineData);
                                const filteredChart7Data = lineData.filter((data) => chart1Times.has(data.time));
                                if (seriesRef7.current) {
                                    seriesRef7.current.setData(filteredChart7Data);
                                }
                            },
                            error: (error) => {
                                throw error;
                            },
                        });
                        Papa.parse(convert_ratioData, {
                            header: true,
                            skipEmptyLines: true,
                            complete: (convert_ratioResults) => {
                                if (convert_ratioResults.errors.length) {
                                    throw new Error('Error parsing intraday trade CSV data');
                                }
                                if (!convert_ratioResults.data.length) {
                                    throw new Error('No intraday trade data available');
                                }
        
                                const lineData = convert_ratioResults.data.map((item) => ({
                                    time: item.date,
                                    value: 100 - (parseFloat(item[symbol]) * 100), 
                                }));
                                const filteredChart8Data = lineData.filter((data) => chart1Times.has(data.time));
                                //console.log(lineData);
                                if (seriesRef8.current) {
                                    seriesRef8.current.setData(filteredChart8Data);
                                }
                            },
                            error: (error) => {
                                throw error;
                            },
                        });

                    },
                    error: (error) => {
                        throw error;
                    },
                });

                Papa.parse(parityData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (parityResults) => {
                        if (parityResults.errors.length) {
                            throw new Error('Error parsing parity CSV data');
                        }
                        if (!parityResults.data.length) {
                            throw new Error('No parity data available');
                        }

                        const baselineData = parityResults.data.map((item) => ({
                            time: item.time,
                            value: parseFloat(item.parity), // Assumes 'parity' is the field name
                        }));

                        baselineSeriesRef.current.setData(baselineData);
                    },
                    error: (error) => {
                        throw error;
                    },
                });

                Papa.parse(premiumData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (premiumResults) => {
                        if (premiumResults.errors.length) {
                            throw new Error('Error parsing premium CSV data');
                        }
                        if (!premiumResults.data.length) {
                            throw new Error('No premium data available');
                        }

                        const lineData = premiumResults.data.map((item) => ({
                            time: item.time,
                            value: parseFloat(item.premium), // Assumes 'premium' is the field name
                        }));

                        lineSeriesRef.current.setData(lineData);
                    },
                    error: (error) => {
                        throw error;
                    },
                });

                Papa.parse(tp02Data, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (tp02Results) => {
                        if (tp02Results.errors.length) {
                            throw new Error('Error parsing tp02 CSV data');
                        }
                        if (!tp02Results.data.length) {
                            throw new Error('No tp02 data available');
                        }

                        const lineData = tp02Results.data.map((item) => ({
                            time: item.time,
                            value: parseFloat(item.theoretical_price_20), // Assumes 'premium' is the field name
                        }));

                        lineSeriesRef1.current.setData(lineData);
                    },
                    error: (error) => {
                        throw error;
                    },
                });

                Papa.parse(tphvData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (tphvResults) => {
                        if (tphvResults.errors.length) {
                            throw new Error('Error parsing tp02 CSV data');
                        }
                        if (!tphvResults.data.length) {
                            throw new Error('No tp02 data available');
                        }

                        const lineData = tphvResults.data.map((item) => ({
                            time: item.time,
                            value: parseFloat(item.theoretical_price_hv), // Assumes 'premium' is the field name
                        }));

                        lineSeriesRef2.current.setData(lineData);
                    },
                    error: (error) => {
                        throw error;
                    },
                });



               

                setLoading(false);
            } catch (error2) {
                console.error('Fetch and Parse Error:', error2);
                setError(`Error: ${error2.message}`);
            }
        };

        fetchData();
    }, [symbol, timeFrame]); // Only re-fetch data when symbol or timeFrame changes

    const handleTimeFrameChange = (newTimeFrame) => {
        setTimeFrame(newTimeFrame);
        let timeRange;
        if (newTimeFrame === 'daily') {
            timeRange = {
                from: Math.floor((Date.now() - 14 * 24 * 60 * 60 * 1000) / 1000), // 7 days ago
                to: Math.floor(Date.now() / 1000), // now
            };
        } else if (newTimeFrame === 'weekly') {
            timeRange = {
                from: Math.floor((Date.now() - 60 * 24 * 60 * 60 * 1000) / 1000), // 30 days ago
                to: Math.floor(Date.now() / 1000), // now
            };
        } else if (newTimeFrame === 'monthly') {
            timeRange = {
                from: Math.floor((Date.now() - 180 * 24 * 60 * 60 * 1000) / 1000), // 1 year ago
                to: Math.floor(Date.now() / 1000), // now
            };
        }
        chartRef.current.timeScale().setVisibleRange(timeRange);
        chartRef2.current.timeScale().setVisibleRange(timeRange);
        chartRef3.current.timeScale().setVisibleRange(timeRange);
        chartRef4.current.timeScale().setVisibleRange(timeRange);
        chartRef5.current.timeScale().setVisibleRange(timeRange);
        chartRef6.current.timeScale().setVisibleRange(timeRange);
        chartRef7.current.timeScale().setVisibleRange(timeRange);
    };

    const handleSubChartToggle = (chartName) => {
        setSubCharts((prevState) => ({
            ...prevState,
            [chartName]: !prevState[chartName],
        }));
    };
    useEffect(() => {
        if (subCharts.marginRatio && !chartRef3.current) {
            const chart3 = createChart(chartContainerRef3.current, {
                width: chartContainerRef3.current.clientWidth,
                height: chartContainerRef3.current.clientHeight,
                layout: {
                    background: { color: '#222' },
                    textColor: '#DDD',
                },
                grid: {
                    vertLines: { color: '#444' },
                    horzLines: { color: '#444' },
                },
                rightPriceScale: {
                    borderColor: '#485c7b',
                },
                leftPriceScale: {
                    borderColor: '#485c7b',
                    scaleMargins: {
                        top: 0.85,
                        bottom: 0,
                    },
                },
                timeScale: {
                    borderColor: '#485c7b',
                    timeVisible: true,
                    secondsVisible: false,
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
            });

            chartRef3.current = chart3;
            seriesRef3.current = chart3.addLineSeries({ color: '#ff8000', lineWidth: 1 });
        }
        if (subCharts.marginLending && !chartRef4.current) {
            const chart = createChart(chartContainerRef4.current, {
                width: chartContainerRef4.current.clientWidth,
                height: chartContainerRef4.current.clientHeight,
                layout: {
                    background: { color: '#222' },
                    textColor: '#DDD',
                },
                grid: {
                    vertLines: { color: '#444' },
                    horzLines: { color: '#444' },
                },
                rightPriceScale: {
                    borderColor: '#485c7b',
                },
                leftPriceScale: {
                    borderColor: '#485c7b',
                    scaleMargins: {
                        top: 0.85,
                        bottom: 0,
                    },
                },
                timeScale: {
                    borderColor: '#485c7b',
                    timeVisible: true,
                    secondsVisible: false,
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
            });

            chartRef4.current = chart;
            seriesRef4.current = chart.addLineSeries({ color: '#ffff00', lineWidth: 1 });
        }
        if (subCharts.marginBorrow && !chartRef5.current) {
            const chart = createChart(chartContainerRef5.current, {
                width: chartContainerRef5.current.clientWidth,
                height: chartContainerRef5.current.clientHeight,
                layout: {
                    background: { color: '#222' },
                    textColor: '#DDD',
                },
                grid: {
                    vertLines: { color: '#444' },
                    horzLines: { color: '#444' },
                },
                rightPriceScale: {
                    borderColor: '#485c7b',
                },
                leftPriceScale: {
                    borderColor: '#485c7b',
                    scaleMargins: {
                        top: 0.85,
                        bottom: 0,
                    },
                },
                timeScale: {
                    borderColor: '#485c7b',
                    timeVisible: true,
                    secondsVisible: false,
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
            });

            chartRef5.current = chart;
            seriesRef5.current = chart.addLineSeries({ color: '#00ffff', lineWidth: 1 });
        }
        if (subCharts.intradayTrade && !chartRef6.current) {
            const chart = createChart(chartContainerRef6.current, {
                width: chartContainerRef6.current.clientWidth,
                height: chartContainerRef6.current.clientHeight,
                layout: {
                    background: { color: '#222' },
                    textColor: '#DDD',
                },
                grid: {
                    vertLines: { color: '#444' },
                    horzLines: { color: '#444' },
                },
                rightPriceScale: {
                    borderColor: '#485c7b',
                },
                leftPriceScale: {
                    borderColor: '#485c7b',
                    scaleMargins: {
                        top: 0.85,
                        bottom: 0,
                    },
                },
                timeScale: {
                    borderColor: '#485c7b',
                    timeVisible: true,
                    secondsVisible: false,
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
            });

            chartRef6.current = chart;
            seriesRef6.current = chart.addLineSeries({ color: '#ff007f', lineWidth: 1 });
        }
    }, [subCharts]);
   // console.log('chartContainerRef5');
 //   console.log(chartRef5.current);
//console.log('chartContainerRef6');
//console.log(chartRef6.current);
    const scrollToInitialPoint = () => {
        if (chartRef.current) {
            chartRef.current.timeScale().scrollToPosition(0, false);
        }
    };
    return (
        <div >
            {error && <div className="error-message">{error}</div>}
            {loading && (
                <div className="loading-spinner"></div>  
            )}
            
            <InfoPanel symbol={symbol} />

            <div style={{ display: 'flex', justifyContent: 'left' }}>
                <button onClick={() => handleTimeFrameChange('daily')}>Daily</button>
                <button onClick={() => handleTimeFrameChange('weekly')}>Weekly</button>
                <button onClick={() => handleTimeFrameChange('monthly')}>Monthly</button>
            <button onClick={scrollToInitialPoint}>回到初始點</button>
            </div>
            
            <div style={{ position: 'relative' }}>
            <div 
                style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                dangerouslySetInnerHTML={{ __html: legend }}
            ></div>
           
            <div
                    className="chart-info-container"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: '10px',
                        zIndex: 900,
                        display: 'flex',
                        //width: '80%',  // Adjust the width to make it smaller
                       // height: '30%',
                        flexDirection: 'column',
                    }}
                >
                  
                  
           
            
                  <div className="collapsible-container" style={{ position: 'absolute', top: '30px', left: '0px', zIndex: 1000 }}>
            <button className="toggle-button" onClick={togglePanel} style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)', border: 'none', color: 'white', padding: '5px', borderRadius: '5px', marginBottom: '5px' }}>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </button>
            {isOpen && (
                <div className="panel-content" >
                    <div style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.75)', padding: '5px', borderRadius: '5px', whiteSpace: 'nowrap' }}>
                        <button onClick={() => setLineVisible_premium(!lineVisible_premium)} style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={lineVisible_premium ? faEye : faEyeSlash} size="lg" />
                        </button>
                        <span style={{ color: '#2962FF', marginLeft: '5px', fontSize: '12px' }}>Premium&nbsp;{legend_premium}</span>
                    </div>
                    <div style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.75)', padding: '5px', borderRadius: '5px', marginLeft: '5px', whiteSpace: 'nowrap' }}>
                        <button onClick={() => setLineVisible_parity(!lineVisible_parity)} style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={lineVisible_parity ? faEye : faEyeSlash} size="lg" />
                        </button>
                        <span style={{ color: 'rgba(239, 83, 80, 1)', marginLeft: '5px', fontSize: '12px' }}>Parity&nbsp;{legend_parity}</span>
                    </div>
                    <div style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.75)', padding: '5px', borderRadius: '5px', marginLeft: '5px', whiteSpace: 'nowrap' }}>
                        <button onClick={() => setLineVisible_theoretical_price(!lineVisible_theoretical_price)} style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={lineVisible_theoretical_price ? faEye : faEyeSlash} size="lg" />
                        </button>
                        <span style={{ color: '#FFFF00', marginLeft: '5px', fontSize: '12px' }}>合理價 20&nbsp;{legend_theoretical_price}</span>
                    </div>
                    <div style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.75)', padding: '5px', borderRadius: '5px', marginLeft: '5px', whiteSpace: 'nowrap' }}>
                        <button onClick={() => setLineVisible_theoretical_price1(!lineVisible_theoretical_price1)} style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={lineVisible_theoretical_price1 ? faEye : faEyeSlash} size="lg" />
                        </button>
                        <span style={{ color: '#ff8000', marginLeft: '5px', fontSize: '12px' }}>合理價 HV&nbsp;{legend_theoretical_price1}</span>
                    </div>
                </div>
            )}
        </div>
               
           
</div>

                <div ref={chartContainerRef} className="chart-container" style={{ top: 0, zIndex: 2  }}></div>
            
            </div>

            <div className="chart-tabs">
                <button
                    className={`button ${visibleCharts['現股'] ? 'active-button' : ''}`}
                    onClick={() => toggleChartVisibility('現股')}
                >
                    現股
                </button>
                <button
                    className={`button ${visibleCharts['CB指標'] ? 'active-button' : ''}`}
                    onClick={() => toggleChartVisibility('CB指標')}
                >
                    CB指標
                </button>
                <button
                    className={`button ${visibleCharts['轉換情形'] ? 'active-button' : ''}`}
                    onClick={() => toggleChartVisibility('轉換情形')}
                >
                    轉換情形
                </button>
                <button
                    className={`button ${visibleCharts['資券'] ? 'active-button' : ''}`}
                    onClick={() => toggleChartVisibility('資券')}
                >
                    資券
                </button>
            </div>
           
            {error && <div>{error}</div>}
            <div className="chart-container1">
            {visibleCharts['現股'] && (
                <>
                    <div  
                        style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                        dangerouslySetInnerHTML={{ __html: legend1 }}
                    ></div>
                   
                </>
            )}

                <div ref={chartContainerRef2} className={`chart-section ${visibleCharts['現股'] ? 'visible' : 'hidden'}`}></div>
                

                {visibleCharts['轉換情形'] && (
                <>
                    <div  
                        style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                        dangerouslySetInnerHTML={{ __html: legend7 }}
                    ></div>
                   
                </>
            )}

                <div ref={chartContainerRef7} className={`chart-section ${visibleCharts['轉換情形'] ? 'visible' : 'hidden'}`}></div>
                {visibleCharts['轉換情形'] && (
                <>
                    <div  
                        style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                        dangerouslySetInnerHTML={{ __html: legend8 }}
                    ></div>
                   
                </>
            )}

                <div ref={chartContainerRef8} className={`chart-section ${visibleCharts['轉換情形'] ? 'visible' : 'hidden'}`}></div>
                {visibleCharts['資券'] && (
                <>
                    <div  
                        style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                        dangerouslySetInnerHTML={{ __html: legend3 }}
                    ></div>
                   
                </>
            )}

                <div ref={chartContainerRef3} className={`chart-section ${visibleCharts['資券'] ? 'visible' : 'hidden'}`}></div>
                
            {visibleCharts['資券'] && (
                <>
                    <div  
                        style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                        dangerouslySetInnerHTML={{ __html: legend4 }}
                    ></div>
                   
                </>
            )}

                <div ref={chartContainerRef4} className={`chart-section ${visibleCharts['資券'] ? 'visible' : 'hidden'}`}></div>
                
            {visibleCharts['資券'] && (
                <>
                    <div  
                        style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                        dangerouslySetInnerHTML={{ __html: legend5 }}
                    ></div>
                   
                </>
            )}

                <div ref={chartContainerRef5} className={`chart-section ${visibleCharts['資券'] ? 'visible' : 'hidden'}`}></div>
                
            {visibleCharts['資券'] && (
                <>
                    <div  
                        style={{ color: 'white', zIndex: 1000, padding: '5px', borderRadius: '5px' }}
                        dangerouslySetInnerHTML={{ __html: legend6 }}
                    ></div>
                   
                </>
            )}

                <div ref={chartContainerRef6} className={`chart-section ${visibleCharts['資券'] ? 'visible' : 'hidden'}`}></div>
            
            </div>


</div>
          


       
    );
    
};

export default ChartComponent;
