import { useState, useLayoutEffect, useRef } from 'react';

function useResponsiveFontSize(containerRef, textContent) {
    const [fontSize, setFontSize] = useState('16px'); // Start with a default
    const textMeasureRef = useRef();

    useLayoutEffect(() => {
        function updateFontSize() {
            if (containerRef.current && textMeasureRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                let currentFontSize = parseFloat(getComputedStyle(textMeasureRef.current).fontSize);

                textMeasureRef.current.style.fontSize = `${currentFontSize}px`;

                // Increase font size until it overflows
                while (textMeasureRef.current.offsetWidth <= containerWidth && currentFontSize < 20) {
                    currentFontSize += 1;
                    textMeasureRef.current.style.fontSize = `${currentFontSize}px`;
                }

                // Decrease font size until it fits
                while (textMeasureRef.current.offsetWidth > containerWidth && currentFontSize > 10) {
                    currentFontSize -= 1;
                    textMeasureRef.current.style.fontSize = `${currentFontSize}px`;
                }

                setFontSize(`${currentFontSize}px`);
            }
        }

        window.addEventListener('resize', updateFontSize);
        updateFontSize(); // Initial call

        return () => window.removeEventListener('resize', updateFontSize);
    }, [textContent]); // Depend on textContent to recalibrate when it changes

    return { fontSize, textMeasureRef };
}

export default useResponsiveFontSize;
