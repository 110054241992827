import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate,Link } from 'react-router-dom';

import SearchBar from './SearchBar';
import Sidebar from './Sidebar';
import ChartComponent from './ChartComponent';
import Modal from 'react-modal';
//import VolumeChartComponent from './VolumeChartComponent';
//import ModifiedSymbolChartComponent from './ModifiedSymbolChartComponent';
import InfoPanel from './InfoPanel';
import FinancePage from './FinancePage';
import BranchPage from './BranchPage';
import IndustryPage from './IndustryPage';
import NewsPage from './NewsPage';
import StockPage from './StockPage';
import CustomStockPage from './CustomStockPage';
import { CrosshairSyncProvider } from './CrosshairSyncContext';
import './App.css';
import Cookies from 'js-cookie';
import RightSidebar from './RightSidebar'; 
import LeftSidebar from './LeftSidebar'; 
Modal.setAppElement('#root'); // Set the app element for accessibility

function App() {
    const [symbol, setSymbol] = useState('64144');
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Login state
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [username, setUsername] = useState('');


    useEffect(() => {
        // Check if user is already logged in
        const savedUsername = Cookies.get('username');
        if (savedUsername) {
            setUsername(savedUsername);
            setIsLoggedIn(true);
        }
    }, []);
    const handleSearch = searchTerm => {
        console.log("Previous symbol:", symbol);
        console.log("New search term:", searchTerm);
        setSymbol(searchTerm);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };
    const openLoginModal = () => {
        //console.log("openLoginModal called");
        setIsLoginModalOpen(true);
    };

    const closeLoginModal = () => {
      //  console.log("closeLoginModal called");
        setIsLoginModalOpen(false);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        const defaultUsername = 'admin';
        const defaultPassword = 'admin';
        const enteredUsername = e.target.username.value;
        const enteredPassword = e.target.password.value;

        if (enteredUsername === defaultUsername && enteredPassword === defaultPassword) {
            setIsLoggedIn(true);
            setUsername(enteredUsername);
            Cookies.set('username', enteredUsername, { expires: 7 });
            closeLoginModal();
        } else {
            alert('Invalid login credentials');
        }
    };
    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername('');
        Cookies.remove('username');
    };
    return (
<Router>
    <CrosshairSyncProvider>
        <div className="app-container">
            
            <Sidebar isOpen={isSidebarOpen} onClose={() => setSidebarOpen(false)} />
            <LeftSidebar />
            <div className="main-content">
                <SearchBar
                    onSearch={handleSearch}
                    onToggleSidebar={toggleSidebar}
                    openLoginModal={openLoginModal}
                    isLoggedIn={isLoggedIn}
                    username={username}
                    handleLogout={handleLogout}
                />
                <Routes>
                    <Route path="/stock" element={<ChartComponent symbol={symbol} />} />
                    <Route path="/finance" element={<FinancePage />} />
                    <Route path="/branch" element={<BranchPage />} />
                    <Route path="/industry" element={<IndustryPage />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/custom-stock" element={<CustomStockPage />} />
                    <Route path="/" element={<Navigate to="/stock" replace />} /> {/* Default route */}
                </Routes>
            </div>
            
            <RightSidebar /> {/* Sidebar is part of the flex layout, so it should be inside app-container */}
        </div>
        <Modal
            isOpen={isLoginModalOpen}
            onRequestClose={closeLoginModal}
            contentLabel="Login Modal"
            className="login-modal"
            overlayClassName="login-modal-overlay"
        >
            <h2>帳號登入</h2>
            <form onSubmit={handleLogin}>
                <label>
                    帳號:
                    <input type="text" name="username" required />
                </label>
                <label>
                    密碼:
                    <input type="password" name="password" required />
                </label>
                <button type="submit" className="login-button">登入</button>
                <button type="button" className="cancel-button" onClick={closeLoginModal}>取消</button>
            </form>
        </Modal>
    </CrosshairSyncProvider>
</Router>

    );
}

export default App;
